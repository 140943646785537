export const initialUploadState = {
  files: [],
  queue: [],
  uploading: [],
}
export const UploadActions = {
  LOAD: 'LOAD',
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
}
const uploadReducer = (state, {type, payload}) => {
  switch (type) {
    case UploadActions.LOAD: {
      return {...state, files: [...state.files, ...payload]}
    }
    case UploadActions.ADD: {
      const queue = [...state.queue, ...payload]
      return {...state, queue}
    }
    case UploadActions.UPLOADING: {
      const queue = state.queue.filter(f => f !== payload)
      return {...state, queue, uploading: [...state.uploading, payload]}
    }
    case UploadActions.UPLOADED: {
      const uploading = state.uploading.filter(f => f !== payload.raw)
      return {...state, uploading, files: [...state.files, payload.file]}
    }
    case UploadActions.REMOVE: {
      return {
        ...state,
        files: state.files.filter(f => f.id !== payload)
      }
    }
    default:
      return state
  }
}
export default uploadReducer
