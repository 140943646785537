import {Tab} from "bootstrap"

document.addEventListener("turbo:load", () => {
    [].slice.call(document.querySelectorAll('[role="tablist"] a')).map((element) => {
        const tabTrigger = new Tab(element)
        element.addEventListener('click', function (event) {
            event.preventDefault()
            tabTrigger.show()
        })
    });
})
