import {Controller} from '@hotwired/stimulus';
import consumer from "./../channels/consumer"

export default class extends Controller {
  static targets = [ "title", "description", "content" ]

  constructor(props) {
    super(props)
    const resourceId = this.element.dataset.resourceId
    const resourceType = this.element.dataset.resourceType
    const locale = this.element.dataset.locale
    const context = this

    this.subscription = consumer.subscriptions.create({ channel: "Hq::GptChannel", resource_id: resourceId, resource_type: resourceType, locale: locale }, {
      connected() {
        console.log("gpt subscription connected")
      },
      received(response) {
        console.log("gpt subscription data received")
        console.log({response})
        if(response.success) {

          if(response.title)
            context.titleTarget.value = response.title

          if(response.description)
            context.descriptionTarget.value = response.description

          if(response.content){
            context.contentTarget.dispatchEvent(
              new CustomEvent("sync-editor", {
                detail: { newValue: response.content },
              })
            )
          }
        } else {
          console.log("error occurred!")
        }
      },
    })
  }

  connect() {
    console.log("gpt-generate connected")
  }

  disconnect() {
    console.log("gpt-generate disconnected")
    if(this.subscription) {
      consumer.subscriptions.remove(this.subscription);
    }
  }
}
