import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(cname, cvalue, exdays=5) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function assignBody(value, ctx) {
  const left = ctx.toggleButton.children[0]
  const right = ctx.toggleButton.children[1]
  document.body.dataset.ktAsideMinimize = value
  if(document.body.dataset.ktAsideMinimize === 'off') {
    left.classList.add('d-none')
    right.classList.remove('d-none')
  } else {
    left.classList.remove('d-none')
    right.classList.add('d-none')
  }
}

export default class extends Controller {

  initialize() {
    this.toggleButton = document.getElementById('aside-toggle')
    assignBody(getCookie('ktAsideMinimize'), this)
  }

  show(e) {
    e.preventDefault()
    assignBody('off', this)
  }

  toggle(e) {
    e.preventDefault()

    if(document.body.dataset.ktAsideMinimize === 'on') {
      assignBody('off', this)
      setCookie("ktAsideMinimize", "off");
    } else {
      setCookie("ktAsideMinimize", "on");
      assignBody('on', this)
    }
  }
}
