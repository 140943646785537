import PropTypes from 'prop-types';
import React, {useState} from 'react';
import DatePicker from "react-datepicker";

const InputDatePicker = (props) => {
  const [value, setValue] = useState(props.value ? new Date(props.value) : null)
  const format = props.format || "MM/yyyy";
  const {name, placeholder} = props;

  const onChange = (action) => {
    setValue(action)
  }

  return (
    <div>
      <input data-autocomplete-target="hidden" name={name} value={value ? value.toISOString() : ''} type="hidden"/>
      <DatePicker
        className="form-control custom-date-picker"
        selected={value}
        onChange={onChange}
        placeholderText={placeholder}
        dateFormat={format}
        showMonthYearPicker
        showFullMonthYearPicker
        showFourColumnMonthYearPicker
      />
    </div>
  );
};

InputDatePicker.propTypes = {
  name: PropTypes.string.isRequired, // this is passed from the Rails view
  value: PropTypes.string, // this is passed from the Rails view
  placeholder: PropTypes.string, // this is passed from the Rails view
  format: PropTypes.string, // this is passed from the Rails view
};

export default InputDatePicker;
