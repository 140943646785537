import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import consumer from "@/channels/consumer";

export default class extends Controller {
  static targets = [ "source", "caller" ]

  constructor(props) {
    super(props)

    const adminId = this.element.dataset.adminId
    const field = this.element.dataset.field
    const context = this

    this.subscription = consumer.subscriptions.create({ channel: "Hq::GptInputTranslationChannel", field: field, admin_id: adminId }, {
      connected() {
        console.log("gpt subscription connected")
      },
      received(response) {
        console.log("gpt subscription data received")
        console.log({response})
        if(response.gpt_translation_id !== context.gpt_translation_id) {
          console.log('translation ids are not same!')
          return
        }

        context.callerTarget.disabled = false
        if(response.success) {
          context.element.querySelectorAll('[data-locale]').forEach((element) => {
            element.value = response.translations[element.dataset.locale]
          })
        } else {
          console.log("error occurred!")
        }
      },
    })
  }

  call() {
    this.callerTarget.disabled = true

    const field = this.element.dataset.field
    const locales = JSON.parse(this.element.dataset.locales)
    const csrfToken = document.querySelector('meta[name=csrf-token]').content;
    fetch(this.element.dataset.callUrl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken
      },
      body: JSON.stringify({
        gpt_translation: {
          field: field,
          text: this.sourceTarget.value,
          from_language: 'en-US',
          to_languages: locales
        }
      })
    })
    .then(r => r.json())
    .then(json => {
      if(json.success) {
        this.gpt_translation_id = json.gpt_translation_id
      } else {
        alert(json.errors[0])
      }
    })
  }

  disconnect() {
    if(this.subscription) {
      consumer.subscriptions.remove(this.subscription);
    }
  }
}
