import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import Select from 'react-select';

const InputSelectStatic = (props) => {
  const [options, setOptions] = useState(props.options);
  const {name, single_input, multiple, ids, placeholder, url} = props;
  const [values, setValues] = useState(options.filter(a => ids.includes(a.id.toString()) ))

  if(ids.length > 0 && url) {
    useEffect(() => {
      fetch(`${url}.json`)
      .then(resp => resp.json())
      .then(data => {
        setOptions(data)
        setValues(data.filter(a => ids.includes(a.id.toString()) ))
      })
      .catch(error => console.log(error));
    }, [url]);
  }

  const onChange = (selectedValues) => setValues(selectedValues)

  const onMenuOpen = () => {
    if(options.length > 1) {
      return
    }

    fetch(`${url}.json`)
    .then(resp => resp.json())
    .then(setOptions)
    .catch(error => console.log(error));
  }

  const renderInput = () => {
    if(single_input) {
      return <input name={name} value={values.map(a => a.id).join(',')} type="hidden"/>
    }
    return values.map((a, i) => <input key={i} name={name} value={a.id} type="hidden"/>);
  }

  return (
    <div>
      {renderInput()}
      <Select
        cacheOptions
        value={values}
        options={options}
        placeholder={placeholder || 'Select'}
        classNamePrefix="react-static-select"
        isMulti={multiple}
        onChange={onChange}
        onMenuOpen={onMenuOpen}
        getOptionLabel={option => option['name']}
        getOptionValue={option => option['id']}
      />
    </div>
  );
};

InputSelectStatic.propTypes = {
  name: PropTypes.string.isRequired, // this is passed from the Rails view
  ids: PropTypes.array.isRequired, // this is passed from the Rails view
  options: PropTypes.array.isRequired, // this is passed from the Rails view
  multiple: PropTypes.bool.isRequired, // this is passed from the Rails view
  single_input: PropTypes.bool.isRequired, // this is passed from the Rails view
  url: PropTypes.string, // this is passed from the Rails view
  placeholder: PropTypes.string, // this is passed from the Rails view
};

export default InputSelectStatic;
