import Transformer from "./Transformer";
import formSchema from "../components/PropertyComplete/FormSchema";

export class PropertyTransformer extends Transformer {
  static fetch(property) {
    return {
      ...Object.keys(formSchema(property).fields).reduce((acc, key) => {
        acc[key] = property[key]
        return acc
      }, {}),
      property_status_id: property.property_status_id && property.property_status_id.toString(),
      property_type_id: property.property_type_id && property.property_type_id.toString(),
      rooms: property.rooms || [],
    }
  }

  static send(property) {
    const {cover, ...data} = property
    let newData = {
      ...data,
      // category_ids: Array.isArray(categories) ? categories.map(c => c.id) : []
    }
    if(cover.length === 1) {
      newData.cover = cover[0]
    }
    return newData
  }
}
