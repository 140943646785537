import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
  initialize() {
    if(this.element.type == "select-one") {
      this.element.setAttribute('data-action', 'change->remote-frame-change#change')
    } else {
      console.error("not implemented element type")
    }
  }

  change(e) {
    e.preventDefault()
    const arg = `${this.element.dataset.query}=${this.element.value}`;
    this.url = this.element.dataset.url.includes('?') ? `${this.element.dataset.url}&${arg}` : `${this.element.dataset.url}?${arg}`;
    // console.log(this.url)
    fetch(this.url, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
