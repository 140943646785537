import {Controller} from '@hotwired/stimulus';
import {Dropzone} from "dropzone";
import {Turbo} from "@hotwired/turbo-rails"

export default class extends Controller {
  constructor(props) {
    super(props)
    this.url = this.element.dataset.uploadUrl;
    this.clickable = ['.upload-dropzone-btn'];
    this.maxFilesize = 35;
    this.csrfToken = document.querySelector('meta[name=csrf-token]').content;
  }

  connect() {
    let dropzone = new Dropzone(this.element, {
      url: this.url,
      clickable: this.clickable,
      paramName: "file_attachment",
      maxFilesize: this.maxFilesize, // MB
      headers: {
        'X-CSRF-TOKEN': this.csrfToken
      },
    });
    dropzone.on('complete', (file) => {
      if(file.status == "success") {
        const upload = JSON.parse(file.xhr.response)
        console.log({upload})
        console.log(upload.detail_url)
        fetch(upload.detail_url, {
          headers: {
            Accept: "text/vnd.turbo-stream.html"
          }
        })
        .then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
      }

      if (dropzone.getUploadingFiles().length === 0 && dropzone.getQueuedFiles().length === 0) {
        // Do something
      }
    })
  }

  disconnect() {
    console.log("disconnect dropzone")
  }
}
