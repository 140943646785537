import React, {useCallback, useMemo, useState, useRef, useEffect} from 'react'
import useFetch from "react-fetch-hook";
import {Controller, useForm, useWatch} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import MapSelection from "./MapSelection";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {ProjectTransformer} from "../transformers/ProjectTransformer";
import {jsonToFormData} from "../utility/jsonToFormData";
import {toTitleCase} from "../utility/toTitleCase";
import formSchema from "./ProjectComplete/FormSchema";
import DatePicker from "react-datepicker";
import ImageSelect from "./ImageSelect";
import {
  DISTANCE_TO_,
  DURATION_TO_,
  DISTANCES,
  PROPERTY_TYPE_COUNT_,
  PROPERTY_TYPES,
  DURATIONS_BY_CITY, PAYMENT_PLANS, PAYMENT_PLAN_TYPE_
} from "../constants";
import {humanize} from "../utility/humanize";

function getMessage(field) {
  return field && field.message;
}

function getError(errors) {
  let errs = Object.values(errors);
  if (errs.length === 0)
    return ""

  return errs[0];
}

const ProjectCompleteForm = ({project, domainId}) => {
  const features = useFetch(`/hq/${domainId}/features/all`);
  const cities = useFetch(`/hq/${domainId}/cities/all`);
  const defaultValues = ProjectTransformer.fetch(project)
  const [loading, setLoading] = useState(false)
  const [plusCode, setPlusCode] = useState('')
  const [searchPlusCode, setSearchPlusCode] = useState('')

  const [defaultDistricts, setDefaultDistricts] = useState([])
  const [defaultNeighborhoods, setDefaultNeighborhoods] = useState([])

  const mapRef = useRef();

  const {register, control, handleSubmit, setValue, getValues, formState: {errors}} = useForm({
    resolver: yupResolver(formSchema(project)),
    defaultValues: useMemo(() => defaultValues, [])
  });
  const error = getError(errors);
  const latLng = getValues("lat_lng");
  const city = getValues('city');
  const latitude = latLng ? latLng[0] : "";
  const longitude = latLng ? latLng[1] : "";

  useWatch({
    control, name: ['city', 'district', 'lat_lng']
  })

  // const searchCategories = useCallback((query) => new Promise((resolve, reject) => {
  //   fetch(`/hq/${domainId}/categories/search?unscoped=1&q=${query}`)
  //   .then(response => response.json())
  //   .then(response => resolve(response))
  //   .catch(reject)
  // }), [])

  const loadOptions = useCallback((type, query) => new Promise((resolve, reject) => {
    let fetcher;
    if (type === "company") {
      fetcher = fetch(`/hq/${domainId}/companies/search/?q=${query}`)
    } else {
      let path = type === 'district' ? 'districts' : 'neighborhoods'
      let key = type === 'district' ? 'city' : 'district'
      let id = getValues(key) ? getValues(key).id : '';
      fetcher = fetch(`/hq/${domainId}/${path}/search/?q=${query}&${key}_id=${id}`)
    }
    fetcher
    .then(resp => resp.json())
    .then(resolve)
    .catch(reject)
  }), []);

  const loadDefaultOptions = (type) => {
    let path = type === 'district' ? 'districts' : 'neighborhoods'
    let resolver = type === 'district' ? setDefaultDistricts : setDefaultNeighborhoods

    let key = type === 'district' ? 'city' : 'district'
    let id = getValues(key) ? getValues(key).id : '';

    fetch(`/hq/${domainId}/${path}/search/?${key}_id=${id}`)
    .then(resp => resp.json())
    .then(resolver)
    .catch(err => console.log(err))
  }

  function onSubmit(data) {
    console.log('submit', data);
    setLoading(true)
    fetch(`/hq/${domainId}/projects/${project.id}/complete.json`, {
      method: 'POST',
      headers: {
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
      },
      body: jsonToFormData({project: ProjectTransformer.send(data)})
    }).then(res => {
      if (res.status === 200) {
        res.json()
        .then(res => {
          window.location.href = res.detail_path
        })
      } else if (res.status === 422) {
        setLoading(false)
        res.json()
        .then(res => {
          // TODO: show errors in a better way
          alert(res.errors[0])
        })
      } else {
        setLoading(false)
        alert("Error occurred!");
      }
    })
  }


  function setMapLocation(newVal) {
    setValue('lat_lng', newVal)
    const {current = {}} = mapRef;
    current.flyTo(newVal, 14, {
      duration: 2
    });

    if(city && city.key !== 'dubai'){
      return
    }

    fetch(`/hq/${domainId}/api/locations/distances.json?origin=${newVal.join(',')}&city_key=${city.key}`)
    .then(r => r.json())
    .then(r => {
      Object.keys(r.distances).forEach(key => {
        const formKey = DISTANCE_TO_.concat(key)
        setValue(formKey, r.distances[key])
      });

      Object.keys(r.durations).forEach(key => {
        const formKey = DURATION_TO_.concat(key)
        setValue(formKey, r.durations[key])
      });
    }).catch(e => {
      console.error(e)
      alert("Error occurred!");
    });
    // current.setView(newVal, 14);
  }

  useEffect(() => {
    if(searchPlusCode === '') {
      return
    }

    try {
      const result = OpenLocationCode.decode(searchPlusCode)
      setMapLocation([result.latitudeHi, result.longitudeHi]);
    } catch (error) {
      console.error(error)
      fetch(`https://plus.codes/api?address=${searchPlusCode}&key=AIzaSyCW9M8sR5FqxN3HmTkEY_1VEdinGJZ1Vqo`)
      .then(r => r.json())
      .then(r => {
        console.log(r)
        if(r.status === 'OK') {
          const location = r.plus_code.geometry.location

          try {
            let local_code = searchPlusCode.split(' ')[0];
            let global_code = OpenLocationCode.recoverNearest(local_code, location.lat, location.lng);
            const result = OpenLocationCode.decode(global_code);
            setMapLocation([result.latitudeHi, result.longitudeHi]);
          } catch (error) {
            console.log(error)
            setMapLocation([location.lat, location.lng]);
          }

        } else if(r.status === 'ZERO_RESULTS') {
          alert("Location can not found!");
        } else {
          alert("Error occurred!");
        }
      }).catch(e => {
        console.error(e)
        alert("Error occurred!");
      });
    }
  }, [searchPlusCode])

  let durationsByCity = []
  if(city) {
    durationsByCity = DURATIONS_BY_CITY[city.key] || []
  }

  return (<div className="card shadow h-80 p-8">
    <form className="project-complete-form hub-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="row pb-4">
        <div className="col-10">
          <h1>{project.name}</h1>
          <small>{project.ref_no}</small>
        </div>
        <div className="col-2 text-end">
          <label htmlFor="is_active" className="fs-5 fw-bold mb-2">Active</label>
          <div className="form-check form-switch d-flex justify-content-end">
            <input className="form-check-input"
                   type="checkbox"
                   role="switch"
                   id="flexSwitchCheckCheckedActive"
                   {...register('is_active')}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="py-4">
          <label htmlFor="cover" className="fs-5 fw-bold mb-2">Cover</label>
          <div className="d-flex">
            {project.cover.filename &&
              <div className="flex-shrink-1">
                <img src={project.cover.thumb_url} alt={project.cover.filename} height={150}/>
              </div>
            }
            <div className={`flex-grow-1 ${getMessage(errors.cover) ? "is-invalid" : ""}`}>
              <ImageSelect onChange={val => setValue("cover", val)}/>
            </div>
          </div>
          <div className="text-danger py-2">{getMessage(errors.cover)}</div>
        </div>
        <div className="py-4">
          <label className="fs-5 fw-bold mb-2">Locations</label>
          <div className="d-flex justify-content-between">
            <div className="w-100 mr-2">
              <Controller
                name="city"
                control={control}
                render={({field: {onChange, value, ref}}) => <Select
                  value={value}
                  isLoading={cities.isLoading}
                  className={`${getMessage(errors.city) ? "is-invalid" : ""}`}
                  inputRef={ref}
                  classNamePrefix="react-select"
                  getOptionLabel={({name}) => name}
                  getOptionValue={({id}) => id}
                  onChange={val => {
                    onChange(val)
                    loadDefaultOptions('district')
                    setDefaultNeighborhoods([])
                    setValue('district', null)
                    setValue('neighborhood', null)
                  }}
                  options={cities.data}
                />}
              />
              <div className="invalid-feedback">{getMessage(errors.city)}</div>
            </div>
            <div className="w-100 mx-2">
              <Controller
                name="district"
                control={control}
                render={({field: {onChange, value, ref}}) =>
                  <AsyncSelect
                    value={value}
                    defaultOptions={defaultDistricts}
                    className={`${getMessage(errors.district) ? "is-invalid" : ""}`}
                    isDisabled={!city}
                    isClearable
                    inputRef={ref}
                    classNamePrefix="react-select"
                    loadOptions={(key) => loadOptions('district', key)}
                    getOptionLabel={({name}) => name}
                    getOptionValue={({id}) => id}
                    onChange={val => {
                      onChange(val)
                      loadDefaultOptions('neighborhood')
                      setValue('neighborhood', null)
                    }}
                  />
                }
              />
              <div className="invalid-feedback">{getMessage(errors.district)}</div>
            </div>
            <div className="w-100 ml-2">
              <Controller
                name="neighborhood"
                control={control}
                render={({field: {onChange, value, ref}}) =>
                  <AsyncSelect
                    value={value}
                    defaultOptions={defaultNeighborhoods}
                    className={`${getMessage(errors.neighborhood) ? "is-invalid" : ""}`}
                    isDisabled={!getValues('district')}
                    isClearable
                    inputRef={ref}
                    classNamePrefix="react-select"
                    loadOptions={(key) => loadOptions('neighborhood', key)}
                    getOptionLabel={({name}) => name}
                    getOptionValue={({id}) => id}
                    onChange={val => {
                      onChange(val)
                    }}
                  />
                }
              />
              <div className="invalid-feedback">{getMessage(errors.neighborhood)}</div>
            </div>
          </div>
        </div>
        {/*<div className="py-4">*/}
        {/*  <label className="fs-5 fw-bold mb-2">Categories</label>*/}
        {/*  <Controller*/}
        {/*    name="categories"*/}
        {/*    control={control}*/}
        {/*    defaultValue={[]}*/}
        {/*    render={({field: {onChange, value, ref}}) =>*/}
        {/*      <AsyncSelect*/}
        {/*        value={value}*/}
        {/*        className={`${getMessage(errors.categories) ? "is-invalid" : ""}`}*/}
        {/*        isClearable*/}
        {/*        isMulti*/}
        {/*        inputRef={ref}*/}
        {/*        loadOptions={searchCategories}*/}
        {/*        getOptionLabel={({name}) => name}*/}
        {/*        getOptionValue={({id}) => id}*/}
        {/*        onChange={val => {*/}
        {/*          onChange(val)*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    }*/}
        {/*  />*/}
        {/*  <div className="invalid-feedback">{getMessage(errors.categories)}</div>*/}
        {/*</div>*/}
        <div className="py-4">
          <label className="fs-5 fw-bold mb-2">Features</label>
          <div className={`${getMessage(errors.feature_ids) ? "is-invalid" : ""}`}>
            {features.isLoading ? 'Loading...' : features.data.sort((a, b) => a.order - b.order).map(item => <div
              key={`f${item.id}`}
              className="form-check form-check-inline form-check-solid mb-3"
              style={{minWidth: "220px"}}>
              <input className="form-check-input" type="checkbox" id={`f${item.id}`}
                     value={item.id} {...register('feature_ids')}/>
              <label className="form-check-label" htmlFor={`f${item.id}`}>{item.name}</label>
            </div>)}
            <div className="invalid-feedback">{getMessage(errors.feature_ids)}</div>
          </div>
        </div>
      </div>
      <div className="py-4">
        <label className="fs-5 fw-bold mb-2">Project info</label>
        <div className="d-flex flex-wrap">
          <div className="mx-2">
            <small className="mb-2">Price list link</small>
            <input
              className={`form-control form-control-solid ${getMessage(errors.price_list_link) ? "is-invalid" : ""}`}
              placeholder="Price list link"
              type="text"
              {...register('price_list_link')}
            />
            <div className="invalid-feedback">{getMessage(errors.price_list_link)}</div>

          </div>
          <div className="mx-2">
            <small className="mb-2">Total area of land (m2)</small>
            <input
              className={`form-control form-control-solid ${getMessage(errors.total_square_meters) ? "is-invalid" : ""}`}
              placeholder="Total area of land (m2)"
              type="number"
              {...register('total_square_meters')}
            />
            <div className="invalid-feedback">{getMessage(errors.total_square_meters)}</div>
          </div>
          <div className="mx-2">
            <small className="mb-2">Block count</small>
            <input
              className={`form-control form-control-solid ${getMessage(errors.block_count) ? "is-invalid" : ""}`}
              placeholder="Block count"
              type="number"
              {...register('block_count')}
            />
            <div className="invalid-feedback">{getMessage(errors.block_count)}</div>
          </div>
          {PROPERTY_TYPES.map((key, i) => {
              const formKey = PROPERTY_TYPE_COUNT_.concat(key)
              const label = toTitleCase(key).concat(" count")
              return (
                <div key={i} className="mx-2">
                  <small className="mb-2">{label}</small>
                  <input
                    className={`form-control form-control-solid ${getMessage(errors[formKey]) ? "is-invalid" : ""}`}
                    type="number"
                    placeholder={label}
                    {...register(formKey)}
                  />
                  <div className="invalid-feedback">{getMessage(errors[formKey])}</div>
                </div>
              )
            }
          )}
        </div>
      </div>
      <div className="py-4">
        <label className="fs-5 fw-bold mb-2">Payment plan (%)</label>
        <div className="d-flex flex-wrap">
          {PAYMENT_PLANS.map((key, i) => {
              const formKey = PAYMENT_PLAN_TYPE_.concat(key)
              const text = humanize(key)
              return (
                <div key={i} className="mx-2">
                  <small className="mb-2">{text}</small>
                  <div className="input-group m-1">
                    <input
                      className={`form-control form-control-solid ${getMessage(errors[formKey]) ? "is-invalid" : ""}`}
                      placeholder={"Example: 25"}
                      type="number"
                      {...register(formKey)}
                    />
                    <span className="input-group-text">%</span>
                  </div>
                  <div className="invalid-feedback">{getMessage(errors[formKey])}</div>
                </div>
              )
            }
          )}
        </div>
      </div>

      <div className="py-4">
        <label className="fs-5 fw-bold mb-2">Location / Map</label>

        <div className="d-flex justify-content-between">
          <div style={{height: 400}} className={`w-100 ${getMessage(errors.lat_lng) ? "is-invalid" : ""}`}>
            <Controller
              name="lat_lng"
              control={control}
              render={({field: {onChange, value, ref}}) =>
                <MapSelection onChange={onChange} value={value} inputRef={ref} mapRef={mapRef}/>
              }
            />
          </div>

          <div className="d-flex flex-column" style={{minWidth: "200px"}}>
            <div className="w-100 mx-2">
              <label className="fs-5 fw-bold mb-2">Plus Code</label>
              <input
                className={`form-control form-control-solid`}
                placeholder="PlusCode"
                type="text"
                value={plusCode}
                onChange={e => {
                  setPlusCode(e.target.value)
                }}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    setSearchPlusCode(e.target.value)
                    e.preventDefault()
                  }
                }}
              />
              <small className="hint">Press enter to navigate location.</small>
            </div>

            <div className="w-100 mx-2 my-4">
              <label className="fs-5 fw-bold mb-2">Latitude</label>
              <input
                className={`form-control form-control-solid ${getMessage(errors.lat_lng) ? "is-invalid" : ""}`}
                placeholder="Latitude"
                type="number"
                value={latitude}
                onChange={val => {
                  setMapLocation([val.target.value, longitude]);
                }}
              />
            </div>

            <div className="w-100 mx-2 my-4">
              <label className="fs-5 fw-bold mb-2">Longitude</label>
              <input
                className={`form-control form-control-solid ${getMessage(errors.lat_lng) ? "is-invalid" : ""}`}
                placeholder="Longitude"
                value={longitude}
                type="number"
                onChange={val => {
                  setMapLocation([latitude, val.target.value]);
                }}
              />
            </div>
          </div>

        </div>
        <div className="invalid-feedback">{getMessage(errors.lat_lng)}</div>
      </div>

      <div className="py-4">
        <label className="fs-5 fw-bold mb-2">Distances (meter) </label>
        <div className="d-flex flex-wrap">
          {DISTANCES.map((key, i) => {
              const formKey = DISTANCE_TO_.concat(key)
              const text = humanize(key).toLowerCase()
              return (
                <div key={i} className="mx-2">
                  <small className="mb-2">To {text}</small>
                  <div className="input-group m-1">
                    <input
                      className={`form-control form-control-solid ${getMessage(errors[formKey]) ? "is-invalid" : ""}`}
                      placeholder={"Distance to ".concat(text)}
                      type="number"
                      {...register(formKey)}
                    />
                    <span className="input-group-text">meters</span>
                  </div>
                  <div className="invalid-feedback">{getMessage(errors[formKey])}</div>
                </div>
              )
            }
          )}
        </div>
      </div>

      {durationsByCity.length > 0 &&
        <div className="py-4">
          <label className="fs-5 fw-bold mb-2">City distances (minutes & meters)</label>
          <div className="d-flex flex-wrap">
            {durationsByCity.map((key, i) => {
                const durationFormKey = DURATION_TO_.concat(key)
                const distanceFormKey = DISTANCE_TO_.concat(key)
                const text = humanize(key).toLowerCase()
                return (
                  <div key={i} className="mx-2">
                    <small className="mb-2">To {text}</small>
                    <div className="input-group m-1" style={{maxWidth: "200px"}}>
                      <input
                        className={`form-control form-control-solid ${getMessage(errors[durationFormKey]) ? "is-invalid" : ""}`}
                        placeholder={"Duration"}
                        type="number"
                        {...register(durationFormKey)}
                      />
                      <span className="input-group-text">minutes</span>
                    </div>
                    <div className="input-group m-1" style={{maxWidth: "200px"}}>
                      <input
                        className={`form-control form-control-solid ${getMessage(errors[distanceFormKey]) ? "is-invalid" : ""}`}
                        placeholder={"Distance"}
                        type="number"
                        {...register(distanceFormKey)}
                      />
                      <span className="input-group-text">meters</span>
                    </div>
                    <div
                      className="invalid-feedback">{getMessage(errors[durationFormKey]) || getMessage(errors[distanceFormKey])}</div>
                  </div>
                )
              }
            )}
          </div>
        </div>
      }

      <div className="py-4 d-flex justify-content-between">
        <div className="w-100 mr-2">
          <label className="fs-5 fw-bold mb-2">Company</label>
          <Controller
            name="company"
            control={control}
            render={({field: {onChange, value, ref}}) =>
              <AsyncSelect
                value={value}
                className={`${getMessage(errors.company) ? "is-invalid xx" : "xx"}`}
                isClearable
                inputRef={ref}
                classNamePrefix="react-select"
                loadOptions={(key) => loadOptions('company', key)}
                getOptionLabel={({name}) => name}
                getOptionValue={({id}) => id}
                onChange={val => {
                  onChange(val)
                }}
              />
            }
          />
          <div className="invalid-feedback">{getMessage(errors.company)}</div>
        </div>
        <div className="w-100 mx-2">
          <label className="fs-5 fw-bold mb-2">Construction Date</label>
          <Controller
            name="construction_date"
            control={control}
            render={({field: {onChange, value, ref}}) =>
              <DatePicker
                className={`form-control form-control-solid ${getMessage(errors.construction_date) ? "is-invalid" : ""}`}
                selected={value}
                onChange={onChange}
                inputRef={ref}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
                showFourColumnMonthYearPicker
              />
            }
          />
          <div className="invalid-feedback">{getMessage(errors.construction_date)}</div>
        </div>
        <div className="w-100 mx-2">
          <label className="fs-5 fw-bold mb-2">Order</label>
          <input
            className={`form-control form-control-solid ${getMessage(errors.order) ? "is-invalid" : ""}`}
            placeholder="Order"
            type="text"
            {...register('order')}
          />
          <div className="invalid-feedback">{getMessage(errors.order)}</div>
        </div>
        <div className="w-100 ml-2">
          <label className="fs-5 fw-bold mb-2">Installment Enabled?</label>
          <div className="form-check form-switch d-flex pt-3">
            <input className="form-check-input"
                   type="checkbox"
                   role="switch"
                   {...register('enable_installment')}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end py-6">
        <button type="submit" className="btn btn-primary" style={{minWidth: "140px"}} disabled={loading}>
          {loading && "Saving..."}
          {!loading && "Save Changes"}
        </button>
      </div>
      {error &&
        <div className="d-flex justify-content-end invalid-feedback d-block">
          Error: {error.message}
        </div>
      }
    </form>
  </div>)
}

export default ProjectCompleteForm
