import React, {useEffect, useRef} from 'react'
import {useDropzone} from "react-dropzone";
import Styles from "./Styles";
import useGallery from "./hooks/useGallery";

const BucketUploader = ({children, galleryKey}) => {
  const wrapper = useRef()
  const {addQueue} = useGallery()
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': []
    }, onDrop: acceptedFiles => {
      addQueue(acceptedFiles.map(file => Object.assign(file, {
        thumb_url: URL.createObjectURL(file),
        galleryKey
      })))
    }
  });
  function dragEnter(){
    wrapper.current.classList.add("on-drag")
  }
  function dragLeave(){
    wrapper.current.classList.remove("on-drag")
  }
  useEffect(()=>{
    if(wrapper){
      wrapper.current.addEventListener("dragenter", dragEnter)
      wrapper.current.addEventListener("dragover", dragEnter)
      wrapper.current.addEventListener("dragleave", dragLeave)
      wrapper.current.addEventListener("drop", dragLeave)
      return () =>{
        wrapper.current.removeEventListener("dragenter", dragEnter)
        wrapper.current.removeEventListener("dragover", dragEnter)
        wrapper.current.removeEventListener("dragleave", dragLeave)
        wrapper.current.removeEventListener("drop", dragLeave)
      }
    }

  },[wrapper])

  return (<Styles.Container className="card card-body bg-white border-light shadow-sm mb-4" ref={wrapper}>
    <div {...getRootProps({className: 'dropzone'})}>
      <input {...getInputProps()} />
      {children}
    </div>
  </Styles.Container>)
}
BucketUploader.displayName = 'BucketUploader'
export default BucketUploader
