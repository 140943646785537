import React from "react";
import Styles from "./Styles";

const QueueFiles = ({files}) => {
  return <Styles.QueueContainer>
    <h5>Queue</h5>
    <Styles.ImageContainer>
      {
        files.map(file => (
          <Styles.Thumbnail key={file.thumb_url}>
            <Styles.ThumbnailInner>
              <Styles.Image
                src={file.thumb_url}
                onLoad={() => {
                  URL.revokeObjectURL(file.thumb_url)
                }}
              />
            </Styles.ThumbnailInner>
          </Styles.Thumbnail>))
      }
    </Styles.ImageContainer>
  </Styles.QueueContainer>
}
QueueFiles.displayName = 'QueueFiles'
export default QueueFiles
