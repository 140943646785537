import Transformer from "./Transformer";
import formSchema from "../components/ProjectComplete/FormSchema";
import {
  DISTANCE_TO_,
  PROPERTY_TYPES,
  PROPERTY_TYPE_COUNT_,
  ALL_DISTANCES,
  DISTANCES,
  DURATION_TO_,
  DURATIONS_BY_CITY,
  ALL_DURATIONS, PAYMENT_PLANS, PAYMENT_PLAN_TYPE_
} from "../constants"

export class ProjectTransformer extends Transformer {
  static fetch(project) {
    const data = {
      ...Object.keys(formSchema(project).fields).reduce((acc, key) => {
        acc[key] = project[key]
        return acc
      }, {}),
      ...ALL_DISTANCES.reduce((acc, key) => {
        acc[DISTANCE_TO_.concat(key)] = project.distances[key]
        return acc
      }, {}),
      ...ALL_DURATIONS.reduce((acc, key) => {
        acc[DURATION_TO_.concat(key)] = project.durations[key]
        return acc
      }, {}),
      ...PROPERTY_TYPES.reduce((acc, key) => {
        acc[PROPERTY_TYPE_COUNT_.concat(key)] = project.property_type_counts[key]
        return acc
      }, {}),
      ...PAYMENT_PLANS.reduce((acc, key) => {
        acc[PAYMENT_PLAN_TYPE_.concat(key)] = project.payment_plan[key]
        return acc
      }, {}),
      is_active: project.status === 'draft' ? true : project.is_active,
      feature_ids: project.feature_ids.map(id => id.toString()),
      construction_date: project.construction_date ? new Date(project.construction_date) : new Date(),
    }
    if (project.location_lat && project.location_long) {
      data.lat_lng = [project.location_lat, project.location_long]
    }
    return data
  }

  static send(project) {
    const {lat_lng, company, country, city, district, neighborhood, cover, ...data} = project
    let durationsByCity = []
    if(city) {
      durationsByCity = DURATIONS_BY_CITY[city.key] || []
    }

    let newData = {
      ...data,
      // category_ids: Array.isArray(categories) ? categories.map(c => c.id) : [],
      company_id: company?.id,
      country_id: country?.id,
      city_id: city?.id,
      district_id: district?.id,
      neighborhood_id: neighborhood?.id,
      location_lat: lat_lng && lat_lng[0],
      location_long: lat_lng && lat_lng[1],
      distances: [...DISTANCES, ...durationsByCity].reduce((acc, key) => {
        acc[key] = project[DISTANCE_TO_.concat(key)]
        return acc
      }, {}),
      durations: [...durationsByCity].reduce((acc, key) => {
        acc[key] = project[DURATION_TO_.concat(key)]
        return acc
      }, {}),
      property_type_counts: PROPERTY_TYPES.reduce((acc, key) => {
        acc[key] = project[PROPERTY_TYPE_COUNT_.concat(key)]
        return acc
      }, {}),
      payment_plan: PAYMENT_PLANS.reduce((acc, key) => {
        acc[key] = project[PAYMENT_PLAN_TYPE_.concat(key)]
        return acc
      }, {}),
    }
    if (cover.length === 1) {
      newData.cover = cover[0]
    }
    return newData
  }
}
