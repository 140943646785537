import React, {useEffect, useState} from 'react'

const RoomSelection = ({onChange, roomPropertyTypes, rooms, index, value}) => {
  const [roomData, setRoomData] = useState(value)

  function handleRemove() {
    onChange(rooms.filter((r, i) => i !== index))
  }

  function handleChange(key, value) {
    setRoomData({...roomData, [key]: parseInt(value)})
  }

  function handleValueChange(key, target) {
    setRoomData({...roomData, [key]: target.value})
  }

  useEffect(() => {
    onChange(rooms.map((r, i) => i === index ? roomData : r))
  }, [roomData])

  return <div className="d-flex align-items-center flex-row border rounded p-3" style={{marginRight: 0}}>
    <div className="d-flex flex-column">
      <div className="d-flex flex-row">

        <div className="w-100 mx-2 my-1">
          <div className="input-group">
            <span className="input-group-text">Bedroom <span className="ms-1 text-danger">*</span></span>
            <input
              className={`form-control`}
              placeholder="Bedroom count"
              type="number"
              value={roomData.bedroom_count}
              onChange={e => handleChange('bedroom_count', e.target.value)}
            />
          </div>
          <small className="mx-2 float-start">
            Put 0 for studio flats
          </small>
        </div>

        <div className="w-100 mx-2 my-1">
          <div className="input-group">
            <span className="input-group-text">Size (m2)<span className="ms-1 text-danger">*</span></span>
            <input
              className={`form-control`}
              placeholder="Size"
              type="number"
              value={roomData.size}
              onChange={e => handleChange('size', e.target.value)}
            />
          </div>
        </div>

        <div className="w-100 mx-2 my-1">
          <div className="input-group">
            <span className="input-group-text">Bathroom <span className="ms-1 text-danger">*</span></span>
            <input
              className={`form-control`}
              placeholder="Bathroom count"
              type="number"
              value={roomData.bath_count}
              onChange={e => handleChange('bath_count', e.target.value)}
            />
          </div>
        </div>

        <div className="w-100 mx-2 my-1">
          <div className="input-group">
            <span className="input-group-text">Balcony <span className="ms-1 text-danger">*</span></span>
            <input
              className={`form-control`}
              placeholder="Balcony count"
              type="number"
              value={roomData.balcony_count}
              onChange={e => handleChange('balcony_count', e.target.value)}
            />
          </div>
        </div>

        <div className="w-100 mx-2 my-1">
          <div className="input-group">
            <span className="input-group-text">Sub type</span>
            <select
              className={`form-control`}
              value={roomData.property_type_id}
              onChange={e => handleValueChange('property_type_id', e.target)}>
              <option></option>
              {roomPropertyTypes && roomPropertyTypes.map((rpt, i) => <option key={i} value={rpt.id}>{rpt.key}</option>)}
            </select>
          </div>
        </div>
      </div>

      <div className="d-flex flex-row mt-4">
        <div className="w-100 mx-2 my-1">
          <div className="input-group">
            <span className="input-group-text">Salon</span>
            <input
              className={`form-control`}
              placeholder="Salon count"
              type="number"
              value={roomData.salon_count}
              onChange={e => handleChange('salon_count', e.target.value)}
            />
          </div>
        </div>

        <div className="w-100 mx-2 my-1">
          <div className="input-group">
            <span className="input-group-text">Floor</span>
            <input
              className={`form-control`}
              placeholder="Which floor"
              type="number"
              value={roomData.floor}
              onChange={e => handleChange('floor', e.target.value)}
            />
          </div>
        </div>


        <div className="w-100 mx-2 my-1">
          <div className="input-group">
            <span className="input-group-text">Discount(€)</span>
            <input
              className={`form-control`}
              placeholder="Discount"
              type="number"
              value={roomData.discount_price}
              onChange={e => handleChange('discount_price', e.target.value)}
            />
          </div>
        </div>

        <div className="w-100 mx-2 my-1">
          <div className="input-group">
            <span className="input-group-text">Price <span className="ms-1 text-danger">*</span></span>
            <input
              className={`form-control`}
              placeholder="Price"
              type="number"
              value={roomData.base_price}
              onChange={e => handleChange('base_price', e.target.value)}
            />
          </div>
        </div>

        <div className="w-100 mx-2 my-1">
          <div className="input-group">
            <span className="input-group-text">Currency<span className="ms-1 text-danger">*</span></span>
            <select
              className={`form-control`}
              value={roomData.base_currency}
              onChange={e => handleValueChange('base_currency', e.target)}>
              {/* CurrencyConst::USED_FOR_PRICE */}
              <option value="EUR">EUR</option>
              <option value="USD">USD</option>
              <option value="GBP">GBP</option>
              <option value="AED">AED</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div className="d-flex flex-column h-100 py-2">
      <button type="button" onClick={handleRemove} className="btn btn-sm btn-danger">
        <span className="fa fa-minus"></span>
      </button>
    </div>
  </div>
}
RoomSelection.displayName = 'RoomSelection'
export default RoomSelection
