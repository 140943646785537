import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'
import {dispatchEvent} from "@/hq/utility/dispatchEvent";

class DirectUploadHandler {
  constructor(detail, ctx) {
    this.element = ctx.fileInputTarget
    this.ctx = ctx
    this.file = detail.file
    this.directUpload = new DirectUpload(this.file, "/rails/active_storage/direct_uploads", this)
    this.dispatch("initialize")
  }

  start(callback) {
    this.dispatch("start")

    this.directUpload.create((error, attributes) => {
      if (error) {
        this.dispatchError(error)
      } else {
        console.log("attributes")
        // console.log({attributes})
        this.ctx.uploadBlob(attributes)
      }

      this.dispatch("end")
      callback(error)
    })
  }

  uploadRequestDidProgress(event) {
    const progress = event.loaded / event.total * 100
    if (progress) {
      this.dispatch("progress", { progress })
    }
  }

  dispatch(name, detail = {}) {
    detail.file = this.file
    detail.id = this.directUpload.id
    return dispatchEvent(this.element, `direct-upload:${name}`, { detail })
  }

  dispatchError(error) {
    const event = this.dispatch("error", { error })
    if (!event.defaultPrevented) {
      alert(error)
    }
  }

  // DirectUpload delegate

  directUploadWillCreateBlobWithXHR(xhr) {
    this.dispatch("before-blob-request", { xhr })
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.dispatch("before-storage-request", { xhr })
    xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
  }
}

function getInnerHTML(item) {
  return `
        <div class="d-flex flex-column mx-2 image-item">
          <div class="image-preview">
            <img src="${item.url}" alt="${item.name}" height="45" />
            <input type="text" name="${this.model}[${this.field}][][url]" hidden="hidden" value="${item.url}"/>
            <input type="text" name="${this.model}[${this.field}][][name]" hidden="hidden" value="${item.name}"/>
          </div>
          <div class="d-flex flex-row justify-content-center">
            <button class="btn btn-icon btn-active-color-danger" type="button" data-action="click->uploads#removeFromImages">
              <i class="fa fa-trash" style="pointer-events: none;"></i>
            </button>
            <a class="btn btn-icon btn-active-color-primary" href="${item.url}" target="_blank">
              <i class="fa fa-eye"></i>
            </a>
          </div>
        </div>
      `;
}

export default class extends Controller {
  static targets = [ "uploadButton", "images", "fileInput", "buttonText" ]

  initialize() {
    this.uploadButtonTarget.setAttribute('data-action', 'click->uploads#upload')
    this.field = this.element.dataset.field
    this.model = this.element.dataset.model
    JSON.parse(this.element.dataset.uploads).forEach((item => {
      this.imagesTarget.insertAdjacentHTML('beforeend', getInnerHTML.call(this, item))
    }));
  }

  removeFromImages(e) {
    e.target.closest('.image-item').remove()
  }

  upload(e) {
    e.preventDefault()

    this.fileInputTarget.onchange = e => {
      const files = e.target.files;
      this.uploadButtonTarget.classList.add('disabled')
      this.buttonTextTarget.textContent = this.buttonTextTarget.dataset.progress

      const handlers = []
      Array.from(files).forEach((file, index) => {
        let detail = { file: file, id: index }
        this.dispatch("initialize", detail);
        const handler = new DirectUploadHandler(detail, this)
        handlers.push(handler)
      })

      const startNextHandler = () => {
        const handler = handlers.shift()
        if (handler) {
          handler.start(error => {
            if (error) {
              console.log(error)
            } else {
              startNextHandler()
            }
          })
        } else {
          this.uploadButtonTarget.classList.remove('disabled')
          this.buttonTextTarget.textContent = this.buttonTextTarget.dataset.default
        }
      }

      startNextHandler()
    }

    this.fileInputTarget.click();
  }

  uploadBlob(blob) {
    console.log({blob})
    const uploadPath = this.element.dataset.uploadPath;
    const csrfToken = document.querySelector('meta[name=csrf-token]').content

    // File uploaded to cloud, finalize upload on server
    const formData = new FormData()
    formData.append('file_attachment', blob.signed_id)
    formData.append('upload[permission]', 'public')
    formData.append('upload[title]', blob.filename)

    fetch( uploadPath, {
      headers: { 'X-CSRF-TOKEN': csrfToken },
      method: 'POST',
      body: formData
    }).then(r => r.json())
    .then(json => {
      const item = {
        url: json.url,
        name: json.title
      }
      this.imagesTarget.innerHTML += getInnerHTML.call(this, item)
    })
    .catch(e => alert(e.message))
  }
}
