import React, {useEffect, useCallback, useState} from 'react';
import AsyncSelect from "react-select/async";

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

// {section_type, action, section_id, resource_vaults}
const VaultSelect = (props) => {
  const [isDefaultChecked, setIsDefaultChecked] = useState(props.action === "edit");
  const selectedTargetsArray = props.resource_vaults.map(a => {
    return {
      target: a.target,
      target_type: a.target_type,
      target_id: a.target_id,
      uniq_key: `${a.target_type}_${a.target_id}`,
    }
  })
  const selectedTargets = getUniqueListBy(selectedTargetsArray, 'uniq_key')
  // TODO: It is not all vaults
  let selectedVaults = props.resource_vaults.map(a => a.vault);
  const domain_id = props.domain_id;

  const [vaults, setVaults] = useState(selectedVaults)
  const [selectedTarget, setSelectedTarget] = useState(selectedTargets[0] || {})

  const loadOptions = useCallback((query) => new Promise((resolve, reject) => {
    fetch(`/hq/${domain_id}/api/resources/search?q=${query}`)
      .then(response => response.json())
      .then(({projects, properties}) => resolve([...projects, ...properties]))
      .catch(reject)
  }), []);

  const getVaults = useCallback((data) => new Promise((resolve, reject) => {
    const {bucket_id, vault_id, id} = data
    let url = ''
    if (vault_id) {
      setSelectedTarget({target_type: 'Property', target_id: id})
      url = `/hq/${domain_id}/api/vaults/${vault_id}/child_vaults`
    } else {
      setSelectedTarget({target_type: 'Project', target_id: id})
      url = `/hq/${domain_id}/api/buckets/${bucket_id}/child_vaults`
    }

    fetch(url)
      .then(response => response.json())
      .then(response => {
        setVaults(response)
        resolve(response)
      })
      .catch(reject)
  }), []);

  const dynamicProps = () => {
    const target = selectedTargets[0];
    if (target) {
      const exists = {id: target.target_id, name: `${target.target_type} - ${target.target.name}` }
      return {defaultValue: exists, defaultOptions: [exists]}
    } else {
      return {}
    }
  }

  return (<div>
    <AsyncSelect
      isClearable
      loadOptions={(key) => loadOptions(key)}
      getOptionLabel={({name}) => name}
      getOptionValue={({id}) => id}
      onChange={data => {
        setSelectedTarget({})
        setVaults([])
        setIsDefaultChecked(false)
        if (data) {
          getVaults(data)
        }
      }}
      {...dynamicProps()}
    />
    <div className="my-3">
      { vaults.length === 0 &&
        <div className="alert alert-info"> There is no vault!</div>
      }
      {vaults.map(vault => <div className="form-check form-check-inline" key={vault.id}>
        <input
          className="form-check-input"
          name="vaults[ids][]"
          type="checkbox"
          // onChange={}
          defaultChecked={isDefaultChecked}
          id={`rt${vault.id}`}
          value={vault.id}
        />
        <label className="form-check-label" htmlFor={`rt${vault.id}`}>{vault.name}</label>
      </div>)}
      <input data-autocomplete-target="hidden" name="vaults[target_type]" value={selectedTarget && selectedTarget.target_type}
             type="hidden"/>
      <input data-autocomplete-target="hidden" name="vaults[target_id]" value={selectedTarget && selectedTarget.target_id}
             type="hidden"/>
    </div>
  </div>);
};

export default VaultSelect;
