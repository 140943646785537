import * as yup from "yup";

export default function formSchema(_location) {
  return yup.object({
    country: yup.object().required(),
    city: yup.object(),
    district: yup.object(),
    neighborhood: yup.object(),
  }).required();
}

