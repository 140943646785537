import React, {useCallback, useMemo, useState} from 'react'
import useFetch from "react-fetch-hook";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import AsyncSelect from "react-select/async";
import {PropertyTransformer} from "../transformers/PropertyTransformer";
import RoomSelection from "./PropertyComplete/RoomSelection";
import {jsonToFormData} from "../utility/jsonToFormData";
import formSchema from "./PropertyComplete/FormSchema";
import ImageSelect from "./ImageSelect";

function getMessage(field) {
  if (Array.isArray(field)) {
    return field.flat().length ? Object.values(field.flat()[0]).flat()[0].message : 'error'
  } else {
    return field && field.message;
  }
}

function getError(errors) {
  let errs = Object.values(errors);
  if(errs.length === 0)
    return ""

  return errs[0];
}

function generateRoomObject(obj = {}) {
  return Object.assign({}, {
    id: "",
    tmp_id: Date.now(),
    property_type_id: "",
    bedroom_count: "",
    size: "",
    base_price: "",
    base_currency: "EUR",
    discount_price: "",
    bath_count: "",
    balcony_count: "",
    salon_count: "",
    floor: ""
  }, obj)
}

const PropertyCompleteForm = ({property, domainId}) => {
  const propertyStatuses = useFetch(`/hq/${domainId}/property_statuses/all`);
  const propertyTypes = useFetch(`/hq/${domainId}/property_types/all`);
  const defaultValues = PropertyTransformer.fetch(property)
  const [loading, setLoading] = useState(false)

  const {register, control, handleSubmit, setValue, watch, formState: {errors}} = useForm({
    resolver: yupResolver(formSchema(property)),
    defaultValues: useMemo(() => defaultValues, [])
  });
  const error = getError(errors);

  const onRoomsStateChange = (value) => {
    setValue("rooms",  value)
  }
  const roomsContent = watch("rooms")
  const propertyTypeId = watch("property_type_id")
  let propertyType
  let childPropertyTypes = []
  if(!propertyTypes.isLoading) {
    propertyType = propertyTypes.data.find(a => a.id.toString() === propertyTypeId)
    if(property.id >= 3386) {
      childPropertyTypes = propertyType ? propertyType.child_types : [] // CASE-1 use this later
    } else {
      childPropertyTypes = propertyTypes.data.concat(propertyType ? propertyType.child_types : []) // CASE-1 remove this later
    }
  }

  // const searchCategories = useCallback((query) => new Promise((resolve, reject) => {
  //   fetch(`/hq/${domainId}/categories/search?unscoped=1&q=${query}`)
  //     .then(response => response.json())
  //     .then(response => resolve(response))
  //     .catch(reject)
  // }), [])

  function onSubmit(data) {
    console.log('submit', data);
    setLoading(true)
    fetch(`/hq/${domainId}/properties/${property.id}/complete.json`, {
      method: 'POST',
      headers: {
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
      },
      body: jsonToFormData({property: PropertyTransformer.send(data)})
    }).then(res => {
      if (res.status === 200) {
        res.json()
          .then(res => {
            window.location.href = res.detail_path
          })
      } else if (res.status === 422) {
        setLoading(false)
        res.json()
          .then(res => {
            // TODO: show errors in a better way
            alert(res.errors[0])
          })
      } else {
        setLoading(false)
        alert("Error occurred!");
      }
    })
  };

  return (<div className="card shadow h-80 p-8">
    <form className="property-complete-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="row pb-4">
        <div className="col-6">
          <h1>{property.name}</h1>
          <small>{property.ref_no}</small>
        </div>
        <div className="col-2 text-end">
          <label htmlFor="is_popular" className="fs-5 fw-bold mb-2">Popular</label>
          <div className="form-check form-switch d-flex justify-content-end">
            <input className="form-check-input"
                   type="checkbox"
                   role="switch"
                   id="flexSwitchCheckCheckedPopular"
                   {...register('is_popular')}
            />
          </div>
        </div>
        <div className="col-2 text-end">
          <label htmlFor="is_sold" className="fs-5 fw-bold mb-2">Sold</label>
          <div className="form-check form-switch d-flex justify-content-end">
            <input className="form-check-input"
                   type="checkbox"
                   role="switch"
                   id="flexSwitchCheckCheckedSold"
                   {...register('is_sold')}
            />
          </div>
        </div>
        <div className="col-2 text-end">
          <label htmlFor="is_active" className="fs-5 fw-bold mb-2">Active</label>
          <div className="form-check form-switch d-flex justify-content-end">
            <input className="form-check-input"
                   type="checkbox"
                   role="switch"
                   id="flexSwitchCheckCheckedActive"
                   {...register('is_active')}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="py-4">
          <label htmlFor="cover" className="fs-5 fw-bold mb-2">Cover</label>
          <div className="d-flex">
            {property.cover.filename &&
              <div className="flex-shrink-1">
                <img src={property.cover.thumb_url} alt={property.cover.filename} height={150}/>
              </div>
            }
            <div className={`flex-grow-1 ${getMessage(errors.cover) ? "is-invalid" : ""}`}>
              <ImageSelect onChange={val => setValue("cover", val)}/>
            </div>
          </div>
          <div className="text-danger py-2">{getMessage(errors.cover)}</div>
        </div>
        {/*<div className="py-4">*/}
        {/*  <label className="fs-5 fw-bold mb-2">Categories</label>*/}
        {/*  <Controller*/}
        {/*    name="categories"*/}
        {/*    control={control}*/}
        {/*    defaultValue={[]}*/}
        {/*    render={({field: {onChange, value, ref}}) =>*/}
        {/*      <AsyncSelect*/}
        {/*        value={value}*/}
        {/*        className={`${getMessage(errors.categories) ? "is-invalid" : ""}`}*/}
        {/*        isClearable*/}
        {/*        isMulti*/}
        {/*        inputRef={ref}*/}
        {/*        loadOptions={searchCategories}*/}
        {/*        getOptionLabel={({name}) => name}*/}
        {/*        getOptionValue={({id}) => id}*/}
        {/*        onChange={val => {*/}
        {/*          onChange(val)*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    }*/}
        {/*  />*/}
        {/*  <div className="invalid-feedback">{getMessage(errors.categories)}</div>*/}
        {/*</div>*/}

        <div className="py-4">
          <label className="fs-5 fw-bold mb-2">Property Status</label>
          <div className={`${getMessage(errors.property_status_id) ? "is-invalid" : ""}`}>
            {propertyStatuses.isLoading ? 'Loading...' : propertyStatuses.data.map(item =>
              <div
                key={`ps${item.id}`}
                className="form-check form-check-inline form-check-solid mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  id={`ps${item.id}`}
                  value={+item.id}
                  {...register('property_status_id')}/>
                <label className="form-check-label" htmlFor={`ps${item.id}`}>{item.key} - {item.name}</label>
              </div>
            )}
          </div>
          <div className="invalid-feedback">{getMessage(errors.property_status_id)}</div>
        </div>

        <div className="py-4">
          <label className="fs-5 fw-bold mb-2">Property Type</label>
          <div className={`${getMessage(errors.property_type_id) ? "is-invalid" : ""}`}>
            {propertyTypes.isLoading ? 'Loading...' : propertyTypes.data.map(item => <div
              key={`pt${item.id}`}
              className="form-check form-check-inline form-check-solid mb-3">
              <input className="form-check-input" type="radio" id={`pt${item.id}`} value={item.id}
                     name="pt" {...register('property_type_id')}/>
              <label className="form-check-label" htmlFor={`pt${item.id}`}>{item.name} - {item.key}</label>
            </div>)}
          </div>
          <div className="invalid-feedback">{getMessage(errors.property_type_id)}</div>
        </div>

        <div className="py-4 d-flex justify-content-between">
          <div className="w-100 me-2">
            <label className="fs-5 fw-bold mb-2">Total Floor Number</label>
            <input
              className={`form-control form-control-solid ${getMessage(errors.floor_count) ? "is-invalid" : ""}`}
              placeholder="Floor Number"
              type="number"
              {...register('floor_count')}
            />
            <div className="invalid-feedback">{getMessage(errors.floor_count)}</div>
          </div>
          <div className="w-100 ms-2">
            <label className="fs-5 fw-bold mb-2">Listing Order Value</label>
            <input
              className={`form-control form-control-solid ${getMessage(errors.order) ? "is-invalid" : ""}`}
              placeholder="Order"
              type="number"
              {...register('order')}
            />
            <div className="invalid-feedback">{getMessage(errors.order)}</div>
          </div>
          <div className="w-100 ms-2">
            <label className="fs-5 fw-bold mb-2">Video 360 URL</label>
            <input
              className={`form-control form-control-solid ${getMessage(errors.video_360_url) ? "is-invalid" : ""}`}
              placeholder="video_360_url"
              type="text"
              {...register('video_360_url')}
            />
            <div className="invalid-feedback">{getMessage(errors.video_360_url)}</div>
          </div>
          <div className="w-100 ms-2">
            <label className="fs-5 fw-bold mb-2">Video URL</label>
            <input
              className={`form-control form-control-solid ${getMessage(errors.video_url) ? "is-invalid" : ""}`}
              placeholder="video_url"
              type="text"
              {...register('video_url')}
            />
            <div className="invalid-feedback">{getMessage(errors.video_url)}</div>
          </div>
        </div>

        <div className="py-4">
          <label className="fs-5 fw-bold mb-2">Room Types</label>
          <div className={`d-flex flex-column ${getMessage(errors.rooms) ? "is-invalid" : ""}`}>
            {roomsContent.map((item, i) =>
              <div className="d-flex justify-content-start mb-6" key={`${i}-${item.id}-${item.tmp_id}`}>
                <RoomSelection index={i} rooms={roomsContent} roomPropertyTypes={propertyType && childPropertyTypes}
                               onChange={onRoomsStateChange} value={item}/>
              </div>
            )}
          </div>
          <button type="button" onClick={() => { onRoomsStateChange([...roomsContent, generateRoomObject()]) }} className="btn btn-primary">
            Add Room Type
            <span className="ms-2 fa fa-plus"></span>
          </button>
          <div className="invalid-feedback">Room type data is invalid!</div>
          <div className="invalid-feedback">{getMessage(errors.rooms)}</div>
        </div>
      </div>

      <div className="d-flex justify-content-end py-6">
        <button type="submit" className="btn btn-primary" style={{minWidth: "140px"}} disabled={loading}>
          { loading && "Saving..." }
          { !loading && "Save Changes" }
        </button>
      </div>
      {error && error.message &&
        <div className="d-flex justify-content-end invalid-feedback d-block">
          Error: {error.message}
        </div>
      }
    </form>
  </div>)
}

export default PropertyCompleteForm
