import React from 'react'
import useFetch from "react-fetch-hook";
import GalleryProvider from "./GalleryManager/context/GalleryProvider";
import Gallery from "./GalleryManager/Gallery";

const GalleryManager = ({uploadPath, fetchPath, downloadPath=null}) => {
  const galleries = useFetch(fetchPath);
  return galleries.isLoading ?
    <div>'Loading...'</div> :
    <GalleryProvider galleries={galleries.data} uploadPath={uploadPath}>
      {galleries.data.map(item => <Gallery gallery={item} key={item.id} downloadPath={ downloadPath ? `${downloadPath}?gallery_key=${item.key}` : null }/>)}
    </GalleryProvider>
}
GalleryManager.displayName = 'GalleryManager'

export default GalleryManager
