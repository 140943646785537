import React from "react";
import {GalleryContext} from "./GalleryContext";
import {useCallback, useEffect, useMemo, useReducer, useRef} from "react";
import uploadReducer, {initialUploadState, UploadActions} from "../reducers/UploadReducer";
import {GalleryTransformer} from "../../../transformers/GalleryTransformer";
import {getChecksum} from "../../../utility/getChecksum";

const GalleryProvider = ({children, galleries, uploadPath, ...props}) => {
  const [state, dispatch] = useReducer(uploadReducer, initialUploadState)

  const uploadFile = async function (file) {
    dispatch({
      type: UploadActions.UPLOADING,
      payload: file
    })

    try {
      const csrfToken = document.querySelector('meta[name=csrf-token]').content
      const checksum = await getChecksum(file)

      const postHeaders = new Headers()
      postHeaders.append("Content-Type", "application/json")
      postHeaders.append("X-CSRF-TOKEN", csrfToken)

      const blobParams = {
        filename: file.name,
        content_type: file.type,
        byte_size: file.size,
        checksum: checksum
      }

      // Create pre-signed url
      const postResponse = await fetch("/rails/active_storage/direct_uploads", {
        method: "POST",
        headers: postHeaders,
        body: JSON.stringify({
          blob: blobParams
        }),
      })
      const result = await postResponse.json()
      const { direct_upload: { url, headers }, signed_id } = result

      // Upload file to cloud
      const uploadHeaders = new Headers()
      Object.keys(headers).forEach(key => {
        uploadHeaders.append(key, headers[key])
      });

      const s3response = await fetch(url, {
        method: 'PUT',
        headers: uploadHeaders,
        body: file
      })
      if(!s3response.ok) {
        alert(s3response.statusText)
        return
      }

      // File uploaded to cloud, finalize upload on server
      const formData = new FormData()
      formData.append('gallery_key', file.galleryKey)
      formData.append('file_attachment', signed_id)

      const serverResponse = await fetch(uploadPath, {
        headers: {
          'X-CSRF-TOKEN': csrfToken
        },
        method: 'POST',
        body: formData
      })

      if (serverResponse.status !== 201) {
        alert(serverResponse.statusText)
      } else {
        const responseData = await serverResponse.json()
        dispatch({
          type: UploadActions.UPLOADED,
          payload: {
            raw: file,
            file: GalleryTransformer.fetch(responseData, file.galleryKey)
          }
        })
      }
    } catch (error) {
      alert(error)
    }
  }

  const deleteImage = useCallback((file) => {
    if (confirm('are you want to delete image') == true) {
      fetch(`${uploadPath}/${file.id}.json`, {
        headers: {
          'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content
        },
        method: 'DELETE'
      })
        .then(response => {
          if (response.status === 200) {
            dispatch({
              type: UploadActions.REMOVE,
              payload: file.id
            })
          }
        })
    }
  }, [])

  const addQueue = useCallback((files) => {
    dispatch({
      type: UploadActions.ADD,
      payload: files
    })
  }, [state, dispatch])

  const loadFiles = useCallback((files) => {
    dispatch({
      type: UploadActions.LOAD,
      payload: files
    })
  }, [state, dispatch])

  useEffect(() => {
    const {queue, uploading} = state
    if (queue.length && uploading.length < 1) {
      uploadFile(queue[0])
    }
  }, [state])

  const beforeUnload = useCallback((e) => {
    if (state.queue.length) {
      if (confirm("Upload is processing! Are you sure to leave?") !== true) {
        e.preventDefault()
      } else {
        window.removeEventListener("turbo:before-visit", beforeUnload);
      }
    }
    return true
  }, [state.queue])

  useEffect(() => {
    window.addEventListener("turbo:before-visit", beforeUnload);
    return () => window.removeEventListener("turbo:before-visit", beforeUnload);
  }, [beforeUnload])


  const values = useMemo(() => ({
      ...state,
      galleries,
      uploadPath,
      addQueue,
      loadFiles,
      deleteImage
    }),
    [
      state,
      galleries,
      uploadPath,
      addQueue,
      loadFiles,
      deleteImage
    ])
  return <GalleryContext.Provider
    {...props}
    value={values}
  >
    {children}
  </GalleryContext.Provider>
}
GalleryProvider.displayName = 'GalleryProvider'
export default GalleryProvider
