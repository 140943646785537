import { Controller } from '@hotwired/stimulus'
import {getChecksum} from "@/hq/utility/getChecksum";

const uploadFile = async function (file, uploadPath) {
  try {
    const csrfToken = document.querySelector('meta[name=csrf-token]').content
    const checksum = await getChecksum(file)

    const postHeaders = new Headers()
    postHeaders.append("Content-Type", "application/json")
    postHeaders.append("X-CSRF-TOKEN", csrfToken)

    const blobParams = {
      filename: file.name,
      content_type: file.type,
      byte_size: file.size,
      checksum: checksum
    }

    // Create pre-signed url
    const postResponse = await fetch("/rails/active_storage/direct_uploads", {
      method: "POST",
      headers: postHeaders,
      body: JSON.stringify({
        blob: blobParams
      }),
    })
    const result = await postResponse.json()
    const { direct_upload: { url, headers }, signed_id } = result
    // Upload file to cloud
    const uploadHeaders = new Headers()
    Object.keys(headers).forEach(key => {
      uploadHeaders.append(key, headers[key])
    });

    const s3response = await fetch(url, {
      method: 'PUT',
      headers: uploadHeaders,
      body: file
    })
    if(!s3response.ok) {
      alert(s3response.statusText)
      return
    }

    // File uploaded to cloud, finalize upload on server
    const formData = new FormData()
    formData.append('file_attachment', signed_id)
    formData.append('upload[permission]', 'public')

    const serverResponse = await fetch( uploadPath, {
      headers: {
        'X-CSRF-TOKEN': csrfToken
      },
      method: 'POST',
      body: formData
    })

    if (serverResponse.status !== 201) {
      alert(serverResponse.statusText)
    } else {
      return await serverResponse.json()
    }
  } catch (error) {
    alert(error)
  }
}

export default class extends Controller {
  static targets = [ "urlInput", "fileInput", "uploadButton", "image" ]

  initialize() {
    this.uploadButtonTarget.setAttribute('data-action', 'click->upload#upload')
    this.imageTarget.setAttribute('data-action', 'click->upload#clickImage')
  }

  clickImage(e) {
    e.preventDefault()
    window.open(e.target.src, '_blank').focus();
  }

  upload(e) {
    e.preventDefault()

    this.fileInputTarget.onchange = e => {
      const file = e.target.files[0];
      this.uploadButtonTarget.classList.add('disabled')

      const uploadPath = this.element.dataset.uploadPath;

      this.uploadButtonTarget.children[1].textContent = 'Uploading...'

      uploadFile(file, uploadPath).then(e => {
        this.urlInputTarget.value = e.thumb_url;
        this.imageTarget.src = e.thumb_url;
        this.uploadButtonTarget.classList.remove('disabled')
        this.uploadButtonTarget.children[1].textContent = 'Uploaded'
      })
    }

    this.fileInputTarget.click();
  }
}
