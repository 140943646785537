import React, {useState} from 'react'
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from "react-leaflet";


function LocationMarker({onChange, position}) {
  const map = useMapEvents({
    click(e) {
      onChange([e.latlng.lat, e.latlng.lng])
    },
  })

  return position === null ? null : (
    <Marker position={position}>
      <Popup>You are here</Popup>
    </Marker>
  )
}

const MapSelection = ({onChange, value = [36.549362, 31.996994], mapRef}) => {
  return <MapContainer ref={mapRef} center={value} zoom={14} scrollWheelZoom={false} style={{height: '100%'}}>
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <LocationMarker position={value} onChange={(val) => {
      if (typeof onChange === 'function') {
        onChange(val)
      }
    }
    }/>
  </MapContainer>
}
MapSelection.displayName = 'MapSelection'
export default MapSelection
