// import {$createLinkNode} from '@lexical/link';
// import {$createListItemNode, $createListNode} from '@lexical/list';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
// import {$createHeadingNode, $createQuoteNode} from '@lexical/rich-text';
import {$getEditor, $getRoot, $insertNodes} from 'lexical';
import * as React from 'react';

import {$generateHtmlFromNodes} from '@lexical/html';

import {SettingsContext, useSettings} from './Editor/context/SettingsContext';
import {SharedAutocompleteContext} from './Editor/context/SharedAutocompleteContext';
import {SharedHistoryContext} from './Editor/context/SharedHistoryContext';
import Editor from './Editor/Editor';
import PlaygroundNodes from './Editor/nodes/PlaygroundNodes';
// import DocsPlugin from './Editor/plugins/DocsPlugin';
// import PasteLogPlugin from './Editor/plugins/PasteLogPlugin';
import {TableContext} from './Editor/plugins/TablePlugin';
// import TestRecorderPlugin from './Editor/plugins/TestRecorderPlugin';
import TypingPerfPlugin from './Editor/plugins/TypingPerfPlugin';
import ShEditorTheme from './Editor/themes/ShEditorTheme';
import {$generateNodesFromDOM} from '@lexical/html';

function parseContent(content: string) {
  // In the browser you can use the native DOMParser API to parse the HTML string.
  const parser = new DOMParser();
  const dom = parser.parseFromString(content, 'text/html');
  return dom;
}

function getDefaultValue(name) {
    return function $prepopulatedRichText() {
        const textArea = document.getElementsByName(name)[0]
        const content = textArea.textContent
        const root = $getRoot()
        const editor = $getEditor()
        if (root.getFirstChild() === null) {
            const dom = parseContent(content);

            // Once you have the DOM instance it's easy to generate LexicalNodes.
            const nodes = $generateNodesFromDOM(editor, dom);

            // Select the root
            $getRoot().select();

            // Insert them at a selection.
            $insertNodes(nodes);
        }

        textArea.addEventListener("sync-editor", (e) => {
          editor.update(() => {
            $getRoot().clear();

            const dom = parseContent(e.detail.newValue);

            // Once you have the DOM instance it's easy to generate LexicalNodes.
            const nodes = $generateNodesFromDOM(editor, dom);
            $insertNodes(nodes);
          });
        });

        editor.registerUpdateListener(({editorState}) => {
          // The latest EditorState can be found as `editorState`.
          // To read the contents of the EditorState, use the following API:

          editorState.read(() => {
            // Just like editor.update(), .read() expects a closure where you can use
            // the $ prefixed helper functions.
            const htmlString = $generateHtmlFromNodes(editor, null);
            textArea.textContent = htmlString
            // console.log(htmlString)
          });
        });
    }
}

export default function App({name, editable=true}): JSX.Element {
    const {
        settings: {measureTypingPerf},
    } = useSettings();

    const initialConfig = {
        editable: editable,
        editorState: getDefaultValue(name),
        namespace: 'ShEditor',
        nodes: [...PlaygroundNodes],
        onError: (error: Error) => {
            throw error;
        },
        theme: ShEditorTheme,
    };

    return (
        <SettingsContext>
            <LexicalComposer initialConfig={initialConfig}>
                <div className="sh-editor">
                    <SharedHistoryContext>
                        <TableContext>
                            <SharedAutocompleteContext>
                                <div className="editor-shell">
                                    <Editor/>
                                </div>
                                {/*{isDevPlayground ? <DocsPlugin /> : null}*/}
                                {/*{isDevPlayground ? <PasteLogPlugin /> : null}*/}
                                {/*{isDevPlayground ? <TestRecorderPlugin /> : null}*/}

                                {measureTypingPerf ? <TypingPerfPlugin/> : null}
                            </SharedAutocompleteContext>
                        </TableContext>
                    </SharedHistoryContext>
                </div>
            </LexicalComposer>
        </SettingsContext>
    );
}
