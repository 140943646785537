import {createContext} from "react";

export const GalleryContext = createContext({
  onSubmit: () => {
  },
  onChange: () => {
  },
  addQueue: (files) => {
  },
  loadFiles: (files) => {
  },
  galleries: {},
  uploadPath: '',
  files: [],
  queue: [],
  uploading: []
});
