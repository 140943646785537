import React, {useState} from 'react'
import Select from "react-select";

const AutoTranslationForm = ({auto_translation, languages, existing_languages}) => {
  const [defaultLanguage, setDefaultLanguage] = useState(languages[0])
  const defaultLanguages = languages.filter(l => !existing_languages.includes(l.value) )
  const [selectedLanguageCodes, setSelectedLanguageCodes] = useState(auto_translation.to_languages.length > 0 ? auto_translation.to_languages : defaultLanguages.map(l => l.value))

  const onSourceLanguageChange = (action) => {
    setDefaultLanguage(action)
  }

  const onToLanguagesChange = (action) => {
    if(action.target.checked) {
      setSelectedLanguageCodes([...selectedLanguageCodes, action.target.value])
    } else {
      setSelectedLanguageCodes(selectedLanguageCodes.filter(l => l !== action.target.value))
    }
  }

  return (
    <div className="row">
      <div>
        <label className="fs-5 fw-bold mb-2">Source Language</label>
        <div>
          <input data-autocomplete-target="hidden" name="auto_translation[from_language]"
                 value={defaultLanguage ? defaultLanguage.value.toString() : ''} type="hidden"/>
          <Select
            cacheOptions
            defaultValue={defaultLanguage}
            options={languages}
            classNamePrefix="react-select"
            onChange={onSourceLanguageChange}
            getOptionLabel={(option) => `${option['text']}`}
            getOptionValue={(option) => `${option['value']}`}
          />
        </div>
      </div>

      <div className="py-4">
        <label className="fs-5 fw-bold mb-2">
          Target Languages
          (
            <a className="text-sm btn-link" onClick={e => setSelectedLanguageCodes([])}>
              Clear All
            </a>
          )
          (
            <a className="text-sm btn-link" onClick={e => setSelectedLanguageCodes(languages.map(l => l.value))}>
              Select All
            </a>
          )
        </label>
        <div>
          {languages.filter(el => el !== defaultLanguage).map(item => <div
            key={`f${item.value}`}
            className="form-check form-check-inline form-check-solid mb-3"
            style={{minWidth: "135px"}}>
            <input className="form-check-input"
                   checked={selectedLanguageCodes.includes(item.value) ? 'checked' : ''}
                   name="auto_translation[to_languages][]"
                   type="checkbox" id={`f${item.value}`}
                   onChange={onToLanguagesChange}
                   value={item.value}/>
            <label className="form-check-label" htmlFor={`f${item.value}`}>{item.text}</label>
          </div>)}
        </div>
      </div>
    </div>
  )
}

export default AutoTranslationForm
