function buildData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    if(Array.isArray(data)) {
      data.forEach(item => {
        buildData(formData, item, `${parentKey}[]`);
      });
    } else {
      Object.keys(data).forEach(key => {
        buildData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    }
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

export function jsonToFormData(data) {
  const formData = new FormData();
  buildData(formData, data);
  return formData;
}
