import React from "react";
import Styles from "./Styles";

const UploadingFiles = ({files}) => {
  return <Styles.UploadingContainer>
    <h5>Uploading</h5>
    <Styles.ImageContainer>
      {
        files.map(file => (
          <Styles.UploadingThumbnail key={file.thumb_url}>
            <Styles.ThumbnailInner>
              <Styles.Image
                src={file.thumb_url}
                onLoad={() => {
                  URL.revokeObjectURL(file.thumb_url)
                }}
              />
            </Styles.ThumbnailInner>
          </Styles.UploadingThumbnail>))
      }
    </Styles.ImageContainer>
  </Styles.UploadingContainer>
}
UploadingFiles.displayName = 'UploadingFiles'
export default UploadingFiles
