import BucketUploader from "./BucketUploader";
import React, {useEffect, useMemo} from "react";
import QueueFiles from "./QueueFiles";
import UploadedFiles from "./UploadedFiles";
import useGallery from "./hooks/useGallery";
import {GalleryTransformer} from "../../transformers/GalleryTransformer";
import Styles from "./Styles";
import UploadingFiles from "./UploadingFiles";

const Gallery = ({gallery, downloadPath=null}) => {
  const {files, queue, uploading, uploadPath, loadFiles} = useGallery()
  const vaultQueue = useMemo(() => queue.filter(f => f.galleryKey === gallery.key), [queue])
  const vaultFiles = useMemo(() => files.filter(f => f.galleryKey === gallery.key), [files])
  const vaultUploading = useMemo(() => uploading.filter(f => f.galleryKey === gallery.key), [uploading])
  useEffect(() => {
    fetch(`${uploadPath}?${new URLSearchParams({
      gallery_key: gallery.key,
    })}`)
      .then(response => response.json())
      .then(response => {
        loadFiles(GalleryTransformer.fetchCollection(response.data, gallery.key));
      })
  }, [])
  return <BucketUploader galleryKey={gallery.key}>
    <div className="d-flex flex-row justify-content-between">
      <h5>{gallery.name}</h5>
      {downloadPath &&
        <a className="btn-link" href={downloadPath} onClick={e => e.stopPropagation()} target="_blank">
          <span className="fa fa-file-archive me-1"></span>
          Download
        </a>
      }
    </div>
    <Styles.StateContainer>
      {!!vaultUploading.length && <UploadingFiles files={vaultUploading}/>}
      {!!vaultQueue.length && <QueueFiles files={vaultQueue}/>}
    </Styles.StateContainer>
    <UploadedFiles files={vaultFiles} gallery={gallery} uploadPath={uploadPath}/>
  </BucketUploader>
}
Gallery.displayName = 'Gallery'
export default Gallery
