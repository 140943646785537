import Transformer from "./Transformer";

export class GalleryTransformer extends Transformer {
  static fetch(file, galleryKey) {
    return {...file, galleryKey}
  }

  static send(file) {
    return file
  }
}
