import {useContext} from "react";
import {GalleryContext} from "../context/GalleryContext";

export default function useGallery() {
  const context = useContext(GalleryContext);
  if (!context) {
    throw new Error(`useGallery must be rendered within the GalleryProvider`);
  }
  return context;
}
