import React, {useCallback, useMemo} from 'react'
import useFetch from "react-fetch-hook";
import {Controller, useForm, useWatch} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {LocationTransformer} from "../transformers/LocationTransformer";
import formSchema from "./LocationForm/FormSchema";

function getMessage(field) {
  return field && field.message;
}

const LocationForm = ({location, input_name, domain_id}) => {
  const countries = useFetch(`/hq/${domain_id}/countries/all`);
  const cities = useFetch(`/hq/${domain_id}/cities/all`);
  const defaultValues = LocationTransformer.fetch(location)
  const {control, watch, setValue, getValues, formState: {errors}} = useForm({
    resolver: yupResolver(formSchema(location)),
    defaultValues: useMemo(() => defaultValues, [])
  });

  useWatch({
    control, name: ['country', 'city', 'district', 'neighborhood']
  })

  const loadOptions = useCallback((type, query) => new Promise((resolve, reject) => {
    let path;
    if (type === 'city') {
      path = 'cities';
    } else if (type === 'district') {
      path = 'districts';
    } else {
      path = 'neighborhoods';
    }

    let key;
    if (type === 'city') {
      key = 'country';
    } else if (type === 'district') {
      key = 'city';
    } else {
      key = 'district';
    }

    let id = getValues(key) ? getValues(key).id : '';
    let fetcher = fetch(`/hq/${domain_id}/${path}/search/?q=${query}&${key}_id=${id}`)
    fetcher.then(response => response.json())
      .then(response => resolve(response))
      .catch(reject)
  }), []);

  return (
    <div className="row">
      <div className="py-4">
        <label className="fs-5 fw-bold mb-2">Locations</label>
        <div className="d-flex justify-content-between">
          <div className="w-100 mr-2">
            <input data-autocomplete-target="hidden" name={`${input_name}[country_id]`} value={getValues('country') ? getValues('country').id : ''} type="hidden"/>
            <Controller
              name="country"
              control={control}
              render={({field: {onChange, value, ref}}) => <Select
                value={value}
                isLoading={countries.isLoading}
                className={`${getMessage(errors.country) ? "is-invalid" : ""}`}
                inputRef={ref}
                getOptionLabel={({name}) => name}
                getOptionValue={({id}) => id}
                onChange={val => {
                  onChange(val)
                  setValue('city', null)
                  setValue('district', null)
                  setValue('neighborhood', null)
                }}
                options={countries.data}
              />}
            />
            <div className="invalid-feedback">{getMessage(errors.country)}</div>
          </div>

          <div className="w-100 mx-2">
            <input data-autocomplete-target="hidden" name={`${input_name}[city_id]`} value={getValues('city') ? getValues('city').id : ''} type="hidden"/>
            <Controller
              name="city"
              control={control}
              render={({field: {onChange, value, ref}}) =>
                <AsyncSelect
                  value={value}
                  className={`${getMessage(errors.city) ? "is-invalid" : ""}`}
                  isDisabled={!getValues('country')}
                  isClearable
                  inputRef={ref}
                  loadOptions={(key) => loadOptions('city', key)}
                  getOptionLabel={({name}) => name}
                  getOptionValue={({id}) => id}
                  onChange={val => {
                    onChange(val)
                    setValue('district', null)
                    setValue('neighborhood', null)
                  }}
                />
              }
            />
            <div className="invalid-feedback">{getMessage(errors.city)}</div>
          </div>




          <div className="w-100 mx-2">
            <input data-autocomplete-target="hidden" name={`${input_name}[district_id]`} value={getValues('district') ? getValues('district').id : ''} type="hidden"/>
            <Controller
              name="district"
              control={control}
              render={({field: {onChange, value, ref}}) =>
                <AsyncSelect
                  value={value}
                  className={`${getMessage(errors.district) ? "is-invalid" : ""}`}
                  isDisabled={!getValues('city')}
                  isClearable
                  inputRef={ref}
                  loadOptions={(key) => loadOptions('district', key)}
                  getOptionLabel={({name}) => name}
                  getOptionValue={({id}) => id}
                  onChange={val => {
                    onChange(val)
                    setValue('neighborhood', null)
                  }}
                />
              }
            />
            <div className="invalid-feedback">{getMessage(errors.district)}</div>
          </div>

          <div className="w-100 ml-2">
            <input data-autocomplete-target="hidden" name={`${input_name}[neighborhood_id]`} value={getValues('neighborhood') ? getValues('neighborhood').id : ''} type="hidden"/>
            <Controller
              name="neighborhood"
              control={control}
              render={({field: {onChange, value, ref}}) =>
                <AsyncSelect
                  value={value}
                  className={`${getMessage(errors.neighborhood) ? "is-invalid" : ""}`}
                  isDisabled={!getValues('district')}
                  isClearable
                  inputRef={ref}
                  loadOptions={(key) => loadOptions('neighborhood', key)}
                  getOptionLabel={({name}) => name}
                  getOptionValue={({id}) => id}
                  onChange={val => {
                    onChange(val)
                  }}
                />
              }
            />
            <div className="invalid-feedback">{getMessage(errors.neighborhood)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationForm
