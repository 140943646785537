import PropTypes from 'prop-types';
import React, {useState} from 'react';
import AsyncSelect from 'react-select/async';
import {querySerialize} from "../utility/querySerialize";

const InputSelect = (props) => {
  const defaultValue = props.value ? {id: props.value, name: props.label} : null
  const [value, setValue] = useState(defaultValue)
  const [defaultOptions, setDefaultOptions] = useState(defaultValue ? [defaultValue] : [])
  const {url, name, filters, placeholder} = props;

  const onChange = (action) => setValue(action)

  const promiseOptions = inputValue => {
    if(inputValue.toString().length < 1) {
      return new Promise(resolve => resolve([]))
    }

    return new Promise(resolve => {
      fetch(`${url}.json?q=${inputValue}&${querySerialize(filters)}`)
      .then(resp => resp.json())
      .then(resolve)
      .catch(error => {
        console.log(error);
        resolve([])
      });
    });
  }

  const onMenuOpen = () => {
    if(defaultOptions.length > 1) {
      return
    }

    fetch(`${url}.json?${querySerialize(filters)}`)
    .then((resp) => resp.json())
    .then(setDefaultOptions)
    .catch(error => {
      console.log(error);
    });
  }

  const dynamicProps = () => {
    if (value) {
      return {defaultValue: value, defaultOptions: [value]}
    }

    return {}
  }

  return (
    <div>
      <input data-autocomplete-target="hidden" name={name} value={value ? value.id : ''} type="hidden"/>
      <AsyncSelect
        cacheOptions
        defaultOptions={defaultOptions}
        placeholder={placeholder || 'Select'}
        classNamePrefix="react-select"
        isClearable
        onChange={onChange}
        onMenuOpen={onMenuOpen}
        loadOptions={promiseOptions}
        getOptionLabel={option => option['name']}
        getOptionValue={option => option['id']}
        {...dynamicProps()}
      />
    </div>
  );
};

InputSelect.propTypes = {
  url: PropTypes.string.isRequired, // this is passed from the Rails view
  name: PropTypes.string.isRequired, // this is passed from the Rails view
  label: PropTypes.string, // this is passed from the Rails view
  placeholder: PropTypes.string, // this is passed from the Rails view
  value: PropTypes.string, // this is passed from the Rails view
  filters: PropTypes.object, // this is passed from the Rails view [{"id": "lang_code", "name": "lang_code"}]
};

export default InputSelect;
