export const DISTANCE_TO_ = 'distance_to_'
export const DURATION_TO_ = 'duration_to_'
export const PROPERTY_TYPE_COUNT_ = 'property_type_count_'
export const PAYMENT_PLAN_TYPE_ = 'payment_plan_type_'
export const PAYMENT_PLANS = [
  'down_payment',
  'during_construction',
  'on_handover',
  'post_handover',
]
export const PROPERTY_TYPES = [
  'apartment',
  'villa',
  'residence',
  'commercial',
]
export const DISTANCES = [
  'city',
  'beach',
  'airport',
  'shopping',
];

export const DURATIONS_FOR_CITY = [
  'al_maktoum_international_airport',
  'burj_al_arab',
  'circle_mall',
  'downtown_dubai',
  'dubai_butterfly_garden',
  'dubai_international_airport',
  'dubai_mall',
  'dubai_marina',
  'mall_of_the_emirates',
];

export const DURATIONS_BY_CITY = {
  dubai: [
    'al_maktoum_international_airport',
    'burj_al_arab',
    'circle_mall',
    'downtown_dubai',
    'dubai_butterfly_garden',
    'dubai_international_airport',
    'dubai_mall',
    'dubai_marina',
    'mall_of_the_emirates',
  ]
};

export const ALL_DISTANCES = [...DISTANCES, ...DURATIONS_FOR_CITY]
export const ALL_DURATIONS = [...DURATIONS_FOR_CITY]
