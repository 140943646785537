import PropTypes from 'prop-types';
import React, {useState, useEffect, useRef} from 'react';
import {jsonToFormData} from "../utility/jsonToFormData";

const SelectionEnum = {
  NONE: 1,
  FULL: 2,
  INDETERMINATE: 3,
}

const MessageTypeEnum = {
  INFO: 'info',
  DANGER: 'danger',
}

const FeedPropertyMultiSelect = (props) => {
  const cRef = useRef();
  const [loading, setLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [message, setMessage] = useState() // {type: 'danger', text: 'aa'}
  const [options, setOptions] = useState([])
  const {domain_id, feed_id} = props;
  const [selected, setSelected] = useState([])
  const [allSelected, setAllSelected] = useState(SelectionEnum.NONE)

  const onAllSelectChange = (action) => {
    setAllSelected(action.target.checked)
    if (action.target.checked) {
      setSelected(options.map(it => it.id))
    } else {
      setSelected([])
    }
  }

  const onSelectChange = (action) => {
    const value = parseInt(action.target.value)

    if (action.target.checked) {
      setSelected([...selected, value])
    } else {
      setSelected(selected.filter(l => l !== value))
    }
  }

  const numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
  });

  useEffect(() => {
    if(!selected.length)
      return setAllSelected(SelectionEnum.NONE)

    if(selected.length === options.length)
      return setAllSelected(SelectionEnum.FULL)

    setAllSelected(SelectionEnum.INDETERMINATE)

  }, [selected, options])

  useEffect(() => {
    if( allSelected === SelectionEnum.INDETERMINATE)
      return cRef.current.indeterminate = true;

    cRef.current.indeterminate = false;
  }, [allSelected])

  const fetchResults = () => {
    fetch(`/hq/${domain_id}/properties/search.json?display_able=true&feed_id=${feed_id}&q=${searchQuery}&per_page=50`)
    .then(resp => resp.json())
    .then(data => {
      setOptions(data)
      setSelected([])
      if(!data.length) {
        setMessage({type: MessageTypeEnum.DANGER, text: 'No search result!'})
      }
    }).catch(error => {
      console.log(error);
      setOptions([])
      setSelected([])
    });
  }

  const handleSubmit = (action) => {
    setLoading(true)
    console.log({selected})
    fetch(`/hq/${domain_id}/feed_contents/bulk_create.json`, {
      method: 'POST',
      headers: {
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
      },
      body: jsonToFormData({
        property_ids: selected,
        feed_id: feed_id
      })
    }).then(res => {
      if (res.status === 200) {
        res.json().then(res => {
          setLoading(false)
          setOptions([])
          setSelected([])
          setMessage({ type: MessageTypeEnum.INFO, text: res.message })
        })
      } else if (res.status === 422) {
        setLoading(false)
        res.json().then(res => {
          setMessage({ type: MessageTypeEnum.DANGER, text: res.message })
        })
      } else {
        setLoading(false)
        setMessage({ type: MessageTypeEnum.DANGER, text: "Error occurred!" })
      }
    })
  }

  return (
    <div>
      <div className="row">
        <div className="py-4">
          <label className="fs-5 fw-bold mb-2">Search properties</label>
          <div className="d-flex flex-wrap">
            <div className="input-group">
              <input
                className="string required form-control h-40px"
                placeholder="Search.."
                type="text"
                onChange={e => setSearchQuery(e.target.value)}
              />
              <button type="button" className="btn btn-primary input-group-btn h-40px" onClick={e => fetchResults()}>
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
          { message &&
            <div className={`alert alert-${message.type} mt-2`}>
              {message.text}
            </div>
          }
        </div>
        <div className="py-4">
          <div className="table-responsive">
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr className="fw-bold text-muted">
                  <th className="w-25px">
                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        ref={cRef}
                        checked={allSelected === SelectionEnum.NONE ? '' : 'checked'}
                        onChange={onAllSelectChange}
                      />
                    </div>
                  </th>
                  <th className="min-w-200px">Property Name / RefNo</th>
                  <th className="min-w-150px">From Price</th>
                </tr>
              </thead>
              <tbody>
              {options.map((property, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          checked={selected.includes(property.id) ? 'checked' : ''}
                          type="checkbox"
                          onChange={onSelectChange}
                          value={property.id}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-45px me-5">
                          <img src={property.cover_small_url} style={{objectFit: "cover"}}/>
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                          <a href={`/hq/${domain_id}/properties/${property.id}`} target="_blank" className="text-gray-900 fw-bold text-hover-primary fs-6">
                            {property.ref_no}
                          </a>
                          <span className="text-muted fw-semibold text-muted d-block fs-7">{property.name}</span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-muted fw-semibold text-muted d-block fs-7">
                        {numberFormatter.format(property.from_price)}
                      </span>
                    </td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-3 d-flex justify-content-end">
        <button
          className="btn btn-primary btn-flex flex-center h-40px"
          disabled={!selected.length || loading}
          onClick={handleSubmit}
          type="button">
          {!loading &&
            <span>Save All</span>
          }
          {loading &&
            <span className="show-when-disabled">
              <span className="fas fa-spinner fa-spin me-2"></span>
              Processing...
            </span>
          }
        </button>
      </div>
    </div>
  )
    ;
};

FeedPropertyMultiSelect.propTypes = {
  domain_id: PropTypes.number.isRequired, // this is passed from the Rails view
  feed_id: PropTypes.number.isRequired, // this is passed from the Rails view
};

export default FeedPropertyMultiSelect;
