import * as yup from "yup";
import {
  ALL_DISTANCES,
  ALL_DURATIONS,
  DISTANCE_TO_,
  DURATION_TO_, PAYMENT_PLAN_TYPE_, PAYMENT_PLANS,
  PROPERTY_TYPE_COUNT_,
  PROPERTY_TYPES
} from "../../constants";

export default function formSchema(project) {
  let coverSchema = yup.mixed();
  if (!project.cover.filename) {
    coverSchema = coverSchema.required('File is required').test('file', 'Cover is Required', (value) => !!value.length);
  }

  return yup.object({
    city: yup.object().required(),
    district: yup.object().nullable(),
    neighborhood: yup.object().nullable(),
    company: yup.object().required(),
    is_active: yup.boolean().required(),
    order: yup.number().integer().required(),
    // categories: yup.array(yup.object().required()).nullable().transform((value, originalValue) => value ? value : []),
    enable_installment: yup.boolean(), // location_lat: yup.number().required(),
    lat_lng: yup.array(),
    plus_code: yup.string().nullable(),
    price_list_link: yup.string().nullable(),
    construction_date: yup.string(),
    total_square_meters: yup.number().positive().integer().nullable().transform((_, val) => val ? Number(val) : null),
    block_count: yup.number().positive().integer().nullable().transform((_, val) => val ? Number(val) : null),
    ...ALL_DISTANCES.reduce((acc, key) => {
      acc[DISTANCE_TO_.concat(key)] = yup.number().positive().integer().nullable().transform((_, val) => val ? Number(val) : null)
      return acc
    }, {}),
    ...ALL_DURATIONS.reduce((acc, key) => {
      acc[DURATION_TO_.concat(key)] = yup.number().positive().integer().nullable().transform((_, val) => val ? Number(val) : null)
      return acc
    }, {}),
    ...PROPERTY_TYPES.reduce((acc, key) => {
      acc[PROPERTY_TYPE_COUNT_.concat(key)] = yup.number().positive().integer().nullable().transform((_, val) => val ? Number(val) : null)
      return acc
    }, {}),
    ...PAYMENT_PLANS.reduce((acc, key) => {
      acc[PAYMENT_PLAN_TYPE_.concat(key)] = yup.number().positive().integer().max(100).nullable().transform((_, val) => val ? Number(val) : null)
      return acc
    }, {}),

    cover: coverSchema,
    feature_ids: yup.array().of(yup.number().positive().integer()),
  }).required();
}

