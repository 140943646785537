import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  padding: 0 !important;

  &.on-drag {
    box-shadow: 0 0.1rem 1rem 0.25rem rgb(0 0 100 / 15%) !important
  }

  & > p {
    font-size: 1rem;
  }

  & > em {
    font-size: .8rem;
  }

  .dropzone {
    padding: 1rem;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;

    &.disabled {
      opacity: 0.6;
    }

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
`
const StateContainer = styled.div`
  display: flex
`
const FilesContainer = styled.div`
  flex: 3;
`
const QueueContainer = styled.div`
  flex: 4;
`
const UploadingContainer = styled.div`
  flex: 1;
  margin-bottom: 30px;
`
const ImageContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &:not(:first-child) {
    margin-top: 8px;
  }
`;
const Thumbnail = styled.div`
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  //margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  position: relative;
`
const UploadingThumbnail = styled.div`
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:after {
    content: '';
    position: absolute;
    width: 48px;
    height: 48px;
    left: calc(50% - 24px);
    top: calc(50% - 24px);
    animation: rotation .6s infinite linear;
    border-left: 6px solid rgba(0, 174, 239, .15);
    border-right: 6px solid rgba(0, 174, 239, .15);
    border-bottom: 6px solid rgba(0, 174, 239, .15);
    border-top: 6px solid rgba(0, 174, 239, .8);
    border-radius: 100%;

  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`
const ThumbnailInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden
`
const Image = styled.img`
  display: block;
  height: 90px;
  width: 90px;
  object-fit: cover;
`

export default {
  Container,
  StateContainer,
  ImageContainer,
  FilesContainer,
  QueueContainer,
  UploadingContainer,
  Image,
  ThumbnailInner,
  Thumbnail,
  UploadingThumbnail
}
