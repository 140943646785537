import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import Select from 'react-select';

const InputMultiSelect = (props) => {
  const [values, setValues] = useState(props.values)
  const [options, setOptions] = useState([])
  const {url, name} = props;

  const onChange = (selectedValues) => setValues(selectedValues)

  useEffect(() => {
    fetch(`${url}.json`)
    .then((resp) => resp.json())
    .then(setOptions)
    .catch(function (error) {
      console.log(error);
      setOptions([])
    });
  }, [url]);

  return (
    <div>
      {values.map((a, i) => <input key={i} name={name} value={a.id} type="hidden"/>)}
      <Select
        cacheOptions
        defaultValue={values}
        options={options}
        classNamePrefix="react-select"
        isMulti
        onChange={onChange}
        getOptionLabel={option => option['name'] || option['label'] }
        getOptionValue={option => option['id']}
      />
    </div>
  );
};

InputMultiSelect.propTypes = {
  url: PropTypes.string.isRequired, // this is passed from the Rails view
  name: PropTypes.string.isRequired, // this is passed from the Rails view
  values: PropTypes.array.isRequired, // this is passed from the Rails view
};

export default InputMultiSelect;
