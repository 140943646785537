import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs" // Add this line, to access the sortablejs library
import { FetchRequest } from '@rails/request.js'

// Connects to data-controller="sortable"
export default class extends Controller {
  connect() {
    console.log("Sortable controller connected")

    this.sortable = Sortable.create(this.element, {
      animation: 150,
      ghostClass: "sortable-ghost",
      chosenClass: "sortable-chosen",
      dragClass: "sortable-drag",
      onEnd: this.end.bind(this)
    })
  }

  async end(event) {
    if(event.oldIndex === event.newIndex)
      return

    console.log("Sortable onEnd triggered")

    if(this.element.dataset.url) {
      const request = new FetchRequest('patch', `${this.element.dataset.url}?old_position=${event.oldIndex + 1}&new_position=${event.newIndex + 1}`)
      const response = await request.perform()
      console.log(response)
    }
  }

}
