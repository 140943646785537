import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ['readContainer', 'writeContainer', 'form', 'input'];
  }

  connect() {
    this.type = this.data.get('type');
    this.value = this.data.get('value');

    this.formTarget.addEventListener('turbo:submit-end', (event, data) => {
      if(event.detail.success) {
        this.readContainerTarget.firstElementChild.textContent = this.inputTarget.value;
        this.cancel();
      } else {
        this.inputTarget.classList.add('is-invalid')
        // this.inputTarget.value = this.data.get('value');
        // this.cancel();
      }
    })
  }

  click() {
    this._show(this.writeContainerTarget);
    if (this.hasInputTarget) {
      this.inputTarget.focus();
    }
    this._hide(this.readContainerTarget);
  }

  cancel() {
    this.inputTarget.classList.remove('is-invalid')
    this._show(this.readContainerTarget);
    this._hide(this.writeContainerTarget);
  }

  _show(field, mode = 'inline') {
    field.style.setProperty('display', mode, 'important');
  }

  _hide(field) {
    field.style.setProperty('display', 'none', 'important');
  }
}
