import React from "react";
import Styles from "./Styles";
import useGallery from "./hooks/useGallery";

const UploadedFiles = ({files, gallery, uploadPath}) => {
  const {deleteImage} = useGallery()
  return <Styles.FilesContainer>
    <Styles.ImageContainer data-controller="sortable" data-url={uploadPath + `/move_image/${gallery.key}`}>
      {
        files.map(file => (
          <div key={file.id} className="d-flex flex-column mx-2" style={{cursor: 'grabbing'}}>
            <div className="">
              <Styles.Thumbnail>
                <Styles.ThumbnailInner>
                  <Styles.Image src={file.thumb_url}/>
                </Styles.ThumbnailInner>
              </Styles.Thumbnail>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <button
                className="btn btn-icon btn-active-color-danger"
                onClick={(e) => {
                  e.stopPropagation()
                  deleteImage(file)
                }}>
                <i className="fa fa-trash"></i>
              </button>
              <a className="btn btn-icon btn-active-color-primary" href={file.x_large_url || file.thumb_url} target="_blank" onClick={e => e.stopPropagation()}>
                <i className="fa fa-eye"></i>
              </a>
            </div>
          </div>
        ))
      }
    </Styles.ImageContainer>
  </Styles.FilesContainer>
}
export default UploadedFiles
