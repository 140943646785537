import * as yup from "yup";

export default function formSchema(property) {
  let coverSchema = yup.mixed();
  if (!property.cover.filename) {
    coverSchema = coverSchema.required('File is required').test('file', 'Cover is Required', (value) => !!value.length);
  }

  return  yup.object({
    property_status_id: yup.number().positive().integer().required(),
    property_type_id: yup.number().positive().integer().required(),
    is_active: yup.boolean().required(),
    is_popular: yup.boolean().required(),
    is_sold: yup.boolean().required(),
    order: yup.number().integer().required(),
    floor_count: yup.number().positive().integer().required(),
    video_360_url: yup.mixed(),
    video_url: yup.mixed(),
    cover: coverSchema,
    // categories: yup.array(yup.object().required()).nullable().transform((value, originalValue) => value ? value : []),
    rooms: yup.array(yup.object({
        property_type_id: yup.mixed(),
        bedroom_count: yup.number().integer().min(0).required(),
        size: yup.number().positive().integer().min(0).required(),
        base_price: yup.number().integer().min(0).required(),
        base_currency: yup.string().required(),
        discount_price: yup.number().positive().integer().min(0).nullable().transform((value, originalValue) => isNaN(value) ? null : value),
        bath_count: yup.number().positive().integer().min(0).required(),
        balcony_count: yup.number().integer().min(0).required(),
        floor: yup.number().positive().integer().min(0).nullable().transform((value, originalValue) => isNaN(value) ? null : value),
        salon_count: yup.number().positive().integer().min(0).nullable().transform((value, originalValue) => isNaN(value) ? null : value),
      })
    )
    .nullable()
    .transform((value, originalValue) => value ? value : [])
  }).required();
}
