import PropTypes from 'prop-types';
import React, {useState} from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import {querySerialize} from "../utility/querySerialize";

const InputMultiSelectSearch = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState(props.values)
  const {url, name, filters, create_url} = props;

  const onChange = (selectedValues) => setValues(selectedValues)

  const promiseOptions = inputValue => {
    if(inputValue.toString().length < 1) {
      return new Promise(resolve => resolve([]))
    }

    return new Promise(resolve => {
      fetch(`${url}.json?q=${inputValue}&${querySerialize(filters)}`)
      .then(resp => resp.json())
      .then(resolve)
      .catch(error => {
        console.log(error);
        resolve([])
      });
    });
  }

  const handleCreate = (inputValue) => {
    setIsLoading(true);
    fetch(`${create_url}.json?query=${inputValue}`, {
      method: 'POST',
      headers: {
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content,
      }
    })
    .then((resp) => resp.json())
    .then(data => {
      const newOption = {
        label: data.name,
        name: data.name,
        id: data.id
      };
      setIsLoading(false);
      setValues( [...values, newOption]);
    })
    .catch(error => {
      console.log(error);
    });
  };

  if(create_url) {
    return (
      <div>
        <select name={name} style={{display: "none"}} multiple>
          <option value="" selected="selected"></option>
          {values.map((a, i) => <option key={i} value={a.id} selected="selected"> {a.name} </option>)}
        </select>

        <AsyncCreatableSelect
          cacheOptions
          value={values}
          isDisabled={isLoading}
          isLoading={isLoading}
          defaultOptions
          classNamePrefix="react-select"
          isMulti
          onCreateOption={handleCreate}
          onChange={onChange}
          loadOptions={promiseOptions}
          getOptionLabel={option => option['name'] || option['label'] }
          getOptionValue={option => option['id']}
        />
      </div>
    );
  }

  return (
    <div>
      <select name={name} style={{display: "none"}} multiple>
        <option value="" selected="selected"></option>
        {values.map((a, i) => <option key={i} value={a.id} selected="selected"> {a.name} </option>)}
      </select>

      <AsyncSelect
        cacheOptions
        defaultValue={values}
        defaultOptions
        classNamePrefix="react-select"
        isMulti
        onChange={onChange}
        loadOptions={promiseOptions}
        getOptionLabel={option => option['name']}
        getOptionValue={option => option['id']}
      />
    </div>
  );
};

InputMultiSelectSearch.propTypes = {
  url: PropTypes.string.isRequired, // this is passed from the Rails view
  name: PropTypes.string.isRequired, // this is passed from the Rails view
  values: PropTypes.array.isRequired, // this is passed from the Rails view [{"id": "lang_code", "name": "lang_code"}]
  filters: PropTypes.object, // this is passed from the Rails view [{"id": "lang_code", "name": "lang_code"}]
};

export default InputMultiSelectSearch;
