import Transformer from "./Transformer";
import formSchema from "../components/LocationForm/FormSchema";

export class LocationTransformer extends Transformer {
  static fetch(location) {
    // console.log(location)
    const data = {
      ...Object.keys(formSchema(location).fields).reduce((acc, key) => {
        acc[key] = location[key]
        return acc
      }, {}),
    }
    return data
  }

  static send(location) {
    const {country, city, district, neighborhood, ...data} = location
    let newData = {
      ...data,
      country_id: country?.id,
      city_id: city?.id,
      district_id: district?.id,
      neighborhood_id: neighborhood?.id,
    }
    return newData
  }
}
