import "@hotwired/turbo-rails"
import "../hq_controllers"

// Setup plugins
import '../hq/plugins/bs-alert'
import '../hq/plugins/bs-carousel'
import '../hq/plugins/bs-collapse'
import '../hq/plugins/bs-dropdown'
import '../hq/plugins/bs-modal'
import '../hq/plugins/bs-offcanvas'
import '../hq/plugins/bs-popover'
import '../hq/plugins/bs-tab'
import '../hq/plugins/bs-toast'
import '../hq/plugins/bs-tooltip'


import "react_ujs";
// const context = import.meta.globEager('../hq/components/*.{js,jsx}');
// Object.keys(context).forEach((path) => {
//   let component = context[path].default;
//
//   `import * as ${ component.name } from '${ path }'`;
//
//   window[component.name] = component;
// });

import GalleryManager from '../hq/components/GalleryManager.jsx'
window['GalleryManager'] = GalleryManager;

import ImageSelect from '../hq/components/ImageSelect.jsx'
window['ImageSelect'] = ImageSelect;

import InputSelect from '../hq/components/InputSelect.jsx'
window['InputSelect'] = InputSelect;

import InputMultiSelect from '../hq/components/InputMultiSelect.jsx'
window['InputMultiSelect'] = InputMultiSelect;

import InputMultiSelectSearch from '../hq/components/InputMultiSelectSearch.jsx'
window['InputMultiSelectSearch'] = InputMultiSelectSearch;

import InputSelectStatic from '../hq/components/InputSelectStatic.jsx'
window['InputSelectStatic'] = InputSelectStatic;

import FeedPropertyMultiSelect from '../hq/components/FeedPropertyMultiSelect.jsx'
window['FeedPropertyMultiSelect'] = FeedPropertyMultiSelect;

import InputDatePicker from '../hq/components/InputDatePicker.jsx'
window['InputDatePicker'] = InputDatePicker;

import MapSelection from '../hq/components/MapSelection.jsx'
window['MapSelection'] = MapSelection;

import ProjectCompleteForm from '../hq/components/ProjectCompleteForm.jsx'
window['ProjectCompleteForm'] = ProjectCompleteForm;

import PropertyCompleteForm from '../hq/components/PropertyCompleteForm.jsx'
window['PropertyCompleteForm'] = PropertyCompleteForm;

import VaultSelect from '../hq/components/VaultSelect.jsx'
window['VaultSelect'] = VaultSelect;

import LocationForm from '../hq/components/LocationForm.jsx'
window['LocationForm'] = LocationForm;

import Editor from '../hq/components/Editor.tsx'
window['Editor'] = Editor;

import AutoTranslationForm from '../hq/components/AutoTranslationForm.jsx'
window['AutoTranslationForm'] = AutoTranslationForm;

// direct_uploads.js
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

addEventListener("direct-upload:initialize", event => {
  console.log("direct-upload:initialize")
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename">${file.name}</span>
    </div>
  `)
})

addEventListener("direct-upload:start", event => {
  console.log("direct-upload:start")
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  console.log("direct-upload:progress")
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  console.log("direct-upload:error")
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  console.log("direct-upload:end")
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})
